import React, {Suspense, lazy} from 'react';
import {Switch, Route, BrowserRouter as Router, Redirect} from 'react-router-dom';
import {Layout, Spin, Modal, ModalFuncProps} from 'antd';
import {useRootModel, TbasStore, LayoutStore} from 'app/models';
import Header from 'app/components/header';
import {CallerProvider} from 'app/models/caller';
import {DoctorProvider} from 'app/models/doctor';
import {WithPermission} from 'app/hoc/WithAuth';
import MainRouter, {RouteWrapper} from 'app/modules/cms/router';
import {Modules} from 'app/constants/moduleIds';
import {SideMenu} from './widgets/sidemenu';
import 'antd/dist/antd.css';
import './reset.scss';
import './antd-reset.scss';
import '../../styles/index.scss';
import './index.scss';
const Login = lazy(() => import('app/pages/privatizationLogin'));
const Logout = lazy(() => import('app/pages/logout'));
const DataSupervise = lazy(() => import('app/pages/dataDashboard'));
const ServiceTerms = lazy(() => import('app/pages/serviceTerms'));
const privacyPolicy = lazy(() => import('app/pages/privacyPolicy'));
const LiveStream = lazy(() => import('app/pages/liveBroadcast/live.stream'));
const LivePlayBack = lazy(() => import('app/pages/liveBroadcast/live.playback'));

const unloginStyle = {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
};
type ModalRef = (props: ModalFuncProps) => {destroy: () => void, update: () => void};
type ModalMethods = 'info' | 'success' | 'warning' | 'error' | 'confirm';
const originalModalMethods: Record<string, ModalRef> = {};
['info', 'success', 'warning', 'error', 'confirm'].forEach(modalMethod => {
    originalModalMethods[modalMethod] = Modal[modalMethod as ModalMethods] as ModalRef;
    Modal[modalMethod as ModalMethods] = (props: ModalFuncProps) => {
        const mergedProps = {
            ...props,
            centered: true,
        };
        return originalModalMethods[modalMethod](mergedProps);
    };
});
export default () => {
    const {isFetch, isLogin} = useRootModel();
    // 加载中
    if (isFetch) {
        return (
            <Layout style={unloginStyle}>
                <Spin />
            </Layout>
        );
    }
    return (
        <Layout className="helpage-wrap" id="helpage-wrap">
            <Router basename="/">
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route key="/login" path="/login" component={Login} exact></Route>
                        <Route key="/logout" path="/logout" component={Logout} exact />
                        <Route key="/terms-of-service" path="/terms-of-service" component={ServiceTerms} exact></Route>
                        <Route key="/privacy-policy" path="/privacy-policy" component={privacyPolicy} exact></Route>
                        {!isLogin && <Redirect to="/login" />}
                        <RouteWrapper
                            keys={[Modules.DataStatistics.id]}
                            path="/dataall"
                            component={DataSupervise}
                            key="dataStatistics"
                            exact
                        ></RouteWrapper>
                        <RouteWrapper
                            keys={[Modules.LiveManage.id]}
                            path="/live/stream/:id"
                            component={LiveStream}
                            key="liveStream"
                            exact
                        ></RouteWrapper>
                        <RouteWrapper
                            keys={[Modules.LiveManage.id]}
                            path="/live/playback/:id"
                            component={LivePlayBack}
                            key="live/playback"
                            exact
                        ></RouteWrapper>
                        <Switch>
                            <Route path="/">
                                <LayoutStore.Provider>
                                    <CallerProvider>
                                        <DoctorProvider>
                                            <TbasStore.Provider>
                                                <>
                                                    <Header />
                                                    <div className="helpage-layout">
                                                        <SideMenu></SideMenu>
                                                        <WithPermission>
                                                            <div className="layout-content" key="main">
                                                                <Suspense fallback={<div></div>}>
                                                                    <Switch>
                                                                        <MainRouter></MainRouter>
                                                                    </Switch>
                                                                </Suspense>
                                                            </div>
                                                        </WithPermission>
                                                    </div>
                                                </>
                                            </TbasStore.Provider>
                                        </DoctorProvider>
                                    </CallerProvider>
                                </LayoutStore.Provider>
                            </Route>
                        </Switch>
                    </Switch>
                </Suspense>
            </Router>
        </Layout>
    );
};
