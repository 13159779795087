/**
 * @file index.tsx
 * @author pankairan
 * @date 2022-06-07 14:47
 */
import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useLocation, useHistory} from 'react-router-dom';
import {Badge, Menu, SubMenuProps} from 'antd';
import Icon, {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import {PermissionGuard, OrgSelector} from 'app/components';
import {useRootModel, useOrderModel, useHeadertab, useLayoutModel} from 'app/models';
import SoundMav from 'app/assets/audio/sound.wav';

import './index.scss';

const SubMenu = Menu.SubMenu as (props: SubMenuProps & { eventKey?: string }) => JSX.Element;
export const SideMenu = () => {
    const soundRef = useRef(null);
    const {tabOpenKeys, pathname = '', currentTabsRoutes, setTabOpenKeys} = useHeadertab();
    const [selectedKey, setSelectedKey] = useState<string>(pathname);
    const {orgId, userInfo, orgType} = useRootModel();
    const orderinfo = useOrderModel();
    const {orderCount, actions} = orderinfo;
    const [collapsed, setCollapsed] = useState(false);
    const preOpenKeys = useRef<string[]>(tabOpenKeys);
    const history = useHistory();
    const {isolated} = useLayoutModel() || {};
    const handleSelect = (e: { key: string }) => {
        // 客服中心和数据统计不能被选中
        if (!['/dataall'].includes(e.key)) {
            setSelectedKey(e.key);
        }
    };
    // 将orderCount存储在sessionStorage
    // 只有在orderCount有变化的时候才播放音频
    useEffect(() => {
        const sound: any = soundRef.current;
        const sessionOrderCount = +(localStorage.getItem('orderCount' + orgId) || '');
        if (orderCount > 0 && orderCount > sessionOrderCount && sound) {
            sound?.play();
        }
        if (orderCount && orderCount !== sessionOrderCount) {
            localStorage.setItem('orderCount' + orgId, orderCount + '');
        }
    }, [orderCount, orgId]);

    useEffect(() => {
        if (userInfo) {
            const {currentOrg = {id: -1}, modules} = userInfo || {};
            return actions.loop(currentOrg.id, modules);
        }
    }, [actions, userInfo]);
    useEffect(() => {
        const unlisten = history.listen(location => {
            setSelectedKey(location.pathname);
        });
        return () => {
            unlisten();
        };
    }, [history]);
    useEffect(() => {
        preOpenKeys.current = tabOpenKeys;
    }, [tabOpenKeys]);
    const handleSettabOpenKeys = (openKeys: string[]) => {
        if (!openKeys.length) {
            setTabOpenKeys([]);
        }
        if (
            preOpenKeys.current.length !== openKeys.length
            || openKeys.some(_ => !preOpenKeys.current.includes(_))
        ) {
            const selectKey = openKeys.find(_ => !preOpenKeys.current.includes(_));
            if (selectKey) {
                setTabOpenKeys([selectKey]);
            }
        }
    };

    const renderMenuItemChild = (
        nestedComponent: (() => React.ReactNode) | undefined,
        linkTo: string,
        name: string
    ) => {
        return nestedComponent
            ? nestedComponent()
            : <NavLink
                to={linkTo}
            >
                {name}
            </NavLink>;
    };

    if (isolated) {
        return null;
    }

    return (
        <div className="layout-aside">
            <div className="sidemenu-holder">
                <Menu
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    openKeys={tabOpenKeys}
                    inlineCollapsed={collapsed}
                    onSelect={e => setSelectedKey(e.key)}
                    onOpenChange={handleSettabOpenKeys}
                >
                    {
                        currentTabsRoutes.map(menu => {
                            const isSubMenu = Array.isArray(menu.menuItem);
                            return (
                                <PermissionGuard key={menu.eventKey} keys={menu.permissionKeys}>
                                    {
                                        isSubMenu
                                            ? <SubMenu
                                                title={menu.name}
                                                eventKey={menu.eventKey}
                                                icon={menu.icon}
                                            >
                                                {
                                                    (menu.menuItem as Array<Record<string, any>>).map(subMenu => {
                                                        if (
                                                            subMenu?.isDisplay
                                                            && !subMenu.isDisplay(() => orgType === 1)
                                                        ) {
                                                            return null;
                                                        }
                                                        const isPermission = !!subMenu?.permissionKeys;
                                                        return isPermission
                                                            ? <PermissionGuard
                                                                keys={subMenu.permissionKeys}
                                                                key={subMenu.eventKey}
                                                            >
                                                                <Menu.Item
                                                                    eventKey={subMenu?.eventKey}
                                                                    key={subMenu?.eventKey}
                                                                >
                                                                    {renderMenuItemChild(
                                                                        subMenu?.nestedComponent,
                                                                        subMenu?.linkTo,
                                                                        subMenu?.name
                                                                    )}
                                                                </Menu.Item>
                                                            </PermissionGuard>
                                                            : <Menu.Item
                                                                eventKey={subMenu?.eventKey}
                                                                key={subMenu?.eventKey}
                                                            >
                                                                {renderMenuItemChild(
                                                                    subMenu?.nestedComponent,
                                                                    subMenu?.linkTo,
                                                                    subMenu?.name
                                                                )}
                                                            </Menu.Item>;
                                                    })
                                                }
                                            </SubMenu>
                                            : <Menu.Item
                                                eventKey={menu.eventKey}
                                                icon={menu.icon}
                                                key={menu.eventKey}
                                            >
                                                {renderMenuItemChild(
                                                    menu?.nestedComponent,
                                                    menu?.linkTo || '',
                                                    menu?.name
                                                )}
                                            </Menu.Item>
                                    }
                                </PermissionGuard>
                            );
                        })
                    }
                </Menu>
            </div>
            <audio ref={soundRef} src={SoundMav}></audio>
            <div className="collapsed-toggle" onClick={() => setCollapsed(s => !s)}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                {!collapsed && <span className="collapsed-label">展开/收起</span>}
            </div>
        </div>
    );
};
