/*
 * @Author: shiruiqiang
 * @Date: 2023-08-15 16:37:56
 * @LastEditTime: 2023-12-12 14:37:52
 * @LastEditors: shiruiqiang
 * @FilePath: index.ts
 * @Description: shiruiqiang
 */
import {message} from 'antd';
import {post} from 'app/utils/fetch';
import {useRef, useState} from 'react';
import {UnclaimedOrderCountResponse_OrderCount} from 'proto/order';
import {Modules} from 'app/constants/moduleIds';
import {CommodityTypeList} from 'app/constants';

const defaultCount = {
    service: 0,
    food: 0,
    goods: 0,
    medical: 0,
    orderCount: 0,
    takeAway: 0,
};

const fetchOderInfo = (orgId: number) => {
    return post('/order/unclaimed-count', {orgId})
        .then((res: any) => {
            const {data, error_code} = res;
            if (error_code === 0) {
                const {counts, total = 0} = data;
                const commodityMap = counts.reduce((pre: any, cur: UnclaimedOrderCountResponse_OrderCount) => {
                    const key = CommodityTypeList.find(item => item.value === cur.categoryGroup)?.key;
                    if (key) {
                        return {
                            ...pre,
                            [key]: cur.count,
                        };
                    } else {
                        return pre;
                    }
                }, {});
                return {...defaultCount, ...commodityMap, total};
            } else {
                return CommodityTypeList.reduce((pre, cur) => {
                    return {
                        ...pre,
                        [cur.key]: 0,
                        total: 0,
                    };
                }, {});
            }
        })
        .catch(err => {
            message.error('获取订单信息失败');
        });
};

export const useOrderInfo = () => {
    const [orderInfo, setOrderInfo] = useState(defaultCount);
    const timer = useRef<any>();
    const actions = {
        request: (orgId: number) => {
            fetchOderInfo(orgId).then(res => {
                const {total, ...map} = res;
                map
                    && setOrderInfo({
                        ...map,
                        orderCount: total,
                    });
            });
        },
        reset: (total = 0) => {
            setOrderInfo({...defaultCount, orderCount: total});
        },
        loop: (orgId: number, modules: any[]) => {
            if (
                modules.some(x =>
                    [
                        Modules.ServiceOrder,
                        Modules.FoodsOrder,
                        Modules.GoodsOrder,
                        Modules.MedicalOrder,
                        Modules.TakeAwayOrder,
                        // 订单模块需要通知
                    ]
                        .map(x => x.id)
                        .includes(x.id)
                )
            ) {
                timer.current = setInterval(() => {
                    actions.request(orgId);
                }, 30000);
            }
            return () => {
                clearInterval(timer.current);
            };
        },
    };
    return {...orderInfo, actions};
};
