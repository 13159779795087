import {useState, useEffect} from 'react';

const IsolatedSet = ['onlineconsultation'];
export const ISOLATED_PAGE = 'isolatedPage';
export const ISOLATED_PAGE_ONLINE_CONSULTATION = 'onlineconsultation';

export const useLayout = () => {
    const [isolated, setIsolated] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const mode = params.get(ISOLATED_PAGE);
        if (IsolatedSet.includes(mode || '')) {
            setIsolated(true);
        }
    }, []);
    return {
        isolated,
    };
};
