/*
 * @Author: shiruiqiang
 * @Date: 2023-11-09 14:54:17
 * @LastEditTime: 2023-12-14 11:26:55
 * @LastEditors: shiruiqiang
 * @FilePath: index.tsx
 * @Description: shiruiqiang
 */
import {InputNumber, Slider} from 'antd';
import React, {PropsWithChildren} from 'react';

export const Limiter = (props: PropsWithChildren<{
    value?: number;
    onChange?: (value: number) => void;
    range?: [number, number];
    style?: React.CSSProperties;
    step?: number;
}>) => {
    const {value, range = [1, 100], onChange, children, style = {}, step} = props;
    return <div style={{display: 'flex', alignItems: 'center', width: '100%', ...style}}>
        <Slider
            min={range[0]}
            max={range[1]}
            value={value}
            step={step}
            tooltip={{formatter: value => `${value}%`}}
            style={{width: '100%', marginRight: '16px'}}
            onChange={onChange}
        />
        <InputNumber
            min={range[0]}
            max={range[1]}
            step={step}
            value={value}
            formatter={value => `${value}%`}
            parser={value => Number(value!.replace('%', ''))}
            precision={0}
            style={{marginRight: '16px', width: '140px'}}
            onChange={value => onChange && onChange(value as number)}
        />
        {children}
    </div>;
};
