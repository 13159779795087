/*
 * @Author: shiruiqiang
 * @Date: 2023-08-15 16:37:56
 * @LastEditTime: 2023-10-25 19:25:44
 * @LastEditors: shiruiqiang
 * @FilePath: app.tsx
 * @Description: shiruiqiang
 */
/**
 * @file index.jsx
 *
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {RootStore, OrderStore, PlatformStore, SkillStore} from 'app/models';
import View from './view';
import {PlatformTypeEnum} from 'app/constants';
import {PlatfromConfig} from 'app/constants/platform';
declare global {
    // eslint-disable-next-line no-var
    var process: typeof process;
}

Sentry.setUser({
    username: (() => {
        const n = localStorage.getItem('SENTRY_IDENTIFY_NMAE');
        if (n) {
            return n;
        }
        const m = `${new Date().getTime().toString(16)}-${[1, 2, 3]
            .map(_ => Math.floor(Math.random() * 0xffff).toString(16))
            .join('-')}`;
        localStorage.setItem('SENTRY_IDENTIFY_NMAE', m);
        return m;
    })(),
});

PlatfromConfig.currentPlatform = PlatformTypeEnum.CMS;
const App = Sentry.withProfiler(() => (
    <PlatformStore.Provider initArgs={{usePlatform: [PlatformTypeEnum.CMS]}}>
        <RootStore.Provider>
            <OrderStore.Provider>
                <SkillStore.Provider>
                    <ConfigProvider locale={zhCN}>
                        <View />
                    </ConfigProvider>
                </SkillStore.Provider>
            </OrderStore.Provider>
        </RootStore.Provider>
    </PlatformStore.Provider>
));

ReactDOM.render(<App />, document.getElementById('root'));
