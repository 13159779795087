import React, {HtmlHTMLAttributes, PropsWithChildren} from 'react';
import {HomeIcon, CmsHomeIcon} from 'app/components/icon';
import {PlatformStore} from 'app/models';
import {PlatformTypeEnum} from 'app/constants';
import './index.scss';

export const Section = (props: PropsWithChildren<{
    Icon?: any;
    title?: string;
    titleChild?: React.ReactNode;
    titleNode?: React.ReactNode;
    titleMargin?: boolean;
    titleMarginNum?: number;
}> & HtmlHTMLAttributes<HTMLDivElement>) => {
    const {platform} = PlatformStore.usePlatform();
    const {
        Icon = platform === PlatformTypeEnum.CMS ? <CmsHomeIcon /> : <HomeIcon />,
        title,
        titleNode,
        titleMargin = true,
        titleMarginNum = 24,
        children,
        titleChild,
        className = '',
        ...restProps
    } = props;
    return (
        <div className={'section-com ' + className} {...restProps}>
            {(titleNode || title) && <div className="section-header" style={{marginBottom: titleMargin ? titleMarginNum : 0}}>
                <div className="title">
                    {Icon && <span className="icon middle">
                        <i className="icon-wrapper">{Icon}</i>
                    </span>}
                    <span className="middle">{titleNode || title}</span>
                </div>
                {titleChild ? titleChild : <></>}
            </div>}
            {children}
        </div>
    );
};
