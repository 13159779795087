import {useUserInfo} from './user';
import {useOrderInfo} from './order';
import {useSkills as useSkillModel} from './skills';
import {useHeaderTabs} from './tabs';
import {useLayout} from './layout';
import {createStore} from 'app/utils/hookState';
import {useState} from 'react';
import {PlatformTypeEnum} from 'app/constants';
export const RootStore = createStore({
    userinfo: useUserInfo,
});

export const OrderStore = createStore({
    orderinfo: useOrderInfo,
});
export const PlatformStore = createStore({
    usePlatform: (value: PlatformTypeEnum) => {
        const [platform] = useState(value);
        return {
            platform,
        };
    },
});
export const SkillStore = createStore({skills: useSkillModel});
export const TbasStore = createStore({
    tab: useHeaderTabs,
});
export const LayoutStore = createStore({layout: useLayout});

export const useRootModel = RootStore.userinfo;
export const useOrderModel = OrderStore.orderinfo;
export const useSkills = SkillStore.skills;
export const useHeadertab = TbasStore.tab;
export const useLayoutModel = LayoutStore.layout;
