/*
 * @Author: yulan02
 * @Date: 2022-04-06 16:00:42
 * @Last Modified by: yulan02
 * @Last Modified time: 2022-04-06 16:08:43
 */
import React, {ErrorInfo, ReactNode} from 'react';

interface ErrorBoundaryProps {
    fallback?: ReactNode;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = {
        hasError: false,
    };
    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }
    componentDidCatch(err: Error, info: ErrorInfo) {
        console.error(err);
        console.warn('RenderError:', info.componentStack);
    }
    render() {
        const fallback = this.props.fallback || 'page error';
        if (this.state.hasError) {
            return <div>{fallback}</div>;
        } else {
            return this.props.children;
        }
    }
}


export const withErrorBoundary = <T, >(Com: React.ComponentType<T>) => {
    return (props: T & ErrorBoundaryProps) => {
        const {fallback, ...restProps} = props;
        return <ErrorBoundary fallback={fallback}>
            <Com {...(restProps as unknown as T)}></Com>
        </ErrorBoundary>;
    };

};
export default ErrorBoundary;
