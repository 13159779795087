import {useRootModel} from 'app/models';
import React, {CSSProperties, PropsWithChildren, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

const unloginStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
};
/**
 * 无权限
 * @returns component
 */
export const NoPermission = <div style={unloginStyle}>
    <h2 style={{fontSize: '20px'}}>无权限，请联系管理员</h2>
</div>;

export const WithPermission = (props: PropsWithChildren<{
    fallBack?: React.ReactNode;
}>) => {
    const {hasPermission} = useRootModel();
    const {children, fallBack = NoPermission} = props;
    return (<>
        {
            hasPermission ? children : fallBack
        }
    </>);
};

export const WithLogin = (props: PropsWithChildren<{}>) => {
    const {children} = props;
    const {isLogin, isFetch} = useRootModel();
    return (<>
        {
            (!isLogin && !isFetch)
                ? <Redirect to="/login"></Redirect>
                : children
        }
    </>);
};
