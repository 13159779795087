/**
 * @file index.tsx
 * @author pankairan
 * @date 2022-06-14 16:27
 */
import React, {useState, useMemo, useEffect, useCallback, ReactNode} from 'react';
import {Button, Modal, TreeSelect, Space, Popover} from 'antd';
import styled from 'styled-components';
import {useRootModel, useOrderModel} from 'app/models';
import {OrgItem} from 'proto/login';
const TreeNode = TreeSelect.TreeNode;
import Icon, {DownOutlined} from '@ant-design/icons';
import arrowDown from 'app/assets/icon/arrow-down.svg';
import MenuSearchContainer from './components/MenuSearchContainer';
import HoverTips from '../hoverTips';

const DropdownWrapper = styled.div`
    .recent-orgs {
        padding: 8px 8px;
        margin-bottom: 8px;

        .history{
            margin-bottom: 4px;
            font-size: 12px;
            color: #84868C;
            line-height: 20px;
        }
    }
    .ant-btn,
    .ant-select-tree {
        font-size: 12px;
    }
    .ant-btn{
        background: #F7F7F9;
        border-radius: 2px;
        color: #151B26 !important;
        padding: 0 8px;
        height: unset;

        &:hover {
            color: #2468f2 !important;
        }
    }
`;
const ArrowDownIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 0 !important;
`;

const renderOrgItem = (org: OrgItem) => {
    return (
        <TreeNode value={org.id} title={org.name} disabled={!org.canOperate} key={org.id}>
            {org.children && org.children.map(renderOrgItem)}
        </TreeNode>
    );
};

const getParentKey = (id: React.Key, tree: any): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item: { id: React.Key}) => item.id === id)) {
                parentKey = node.id;
            } else if (getParentKey(id, node.children)) {
                parentKey = getParentKey(id, node.children);
            }
        }
    }
    return parentKey!;
};

// 设置无权限节点为 disabled
const setDisabledProperty = (org: OrgItem): OrgItem & { disabled: boolean } => {
    if (!org.children || org.children.length === 0) {
        return {
            ...org,
            disabled: !org.canOperate || !!org.status,
        };
    }
    return {
        ...org,
        disabled: !org.canOperate || !!org.status,
        children: org.children.map(child => setDisabledProperty(child)),
    };
};

// 过滤掉当前节点及其子节点均无权限的节点
const filterNoPermissionNode = (node: OrgItem): OrgItem | null => {
    if (!node.children || !node.children.length) {
        return node.canOperate ? node : null;
    }
    const children = node.children.map(c => filterNoPermissionNode(c)).filter(o => !!o) as OrgItem[];
    return !node.canOperate && (!children || !children.length) ? null : {
        ...node,
        children: children.length ? children : null,
    } as OrgItem;
};
export default () => {
    const {userInfo, actions} = useRootModel();
    const orderinfo = useOrderModel();
    if (userInfo) {
        const {orgTree, currentOrg = {id: -1}, currentOperateOrgList} = userInfo;
        const [searchValue, setSearchValue] = useState('');
        const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
        const switchOrg = (orgId: number) => {
            if (orgId !== currentOrg.id) {
                const modal = Modal.confirm({
                    title: '确定要切换操作机构吗？',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () =>
                        new Promise(() =>
                            setTimeout(
                                () =>
                                    actions.switchOrg(orgId).then(() => {
                                        const sessionOrderCount = +(sessionStorage.getItem('orderCount' + orgId) || '');
                                        orderinfo.actions.reset(sessionOrderCount);
                                        window.location.reload();
                                    }).catch(() => {
                                        modal.destroy();
                                    }),
                                1000
                            )
                        ),
                });
            }
        };

        const filterTree = (tree: OrgItem[], searchText: string): OrgItem[] => {
            return tree?.map(node => {
                const filteredChildren = filterTree(node.children, searchText);

                if (node.name.includes(searchText) || filteredChildren?.length > 0) {
                    return {
                        ...node,
                        children: filteredChildren,
                    };
                }

                return null;
            }).filter(Boolean) as OrgItem[];
        };
        const getFlatTreeList = useCallback((dataSource: OrgItem[]) => {
            const dataList: Array<{ id: React.Key, name: string }> = [];
            const generateList = (data: any) => {
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    const {id, name} = node;
                    dataList.push({id, name});
                    if (node.children) {
                        generateList(node.children);
                    }
                }
            };
            generateList(dataSource);
            return dataList;
        }, []);
        const flatTreeList = useMemo(() => {
            return getFlatTreeList(orgTree);
        }, [orgTree]);

        const treeData = useMemo(() => {
            const formatOrgTree = (orgTree.map(o => filterNoPermissionNode(o)) as OrgItem[])
                .filter(o => !!o).map(o => setDisabledProperty(o));
            if (!searchValue) {
                return formatOrgTree;
            }
            const filterOrgTree = filterTree(formatOrgTree, searchValue);
            const loop = (data: any): any => {
                return data.map((item: { name: string, children: any, id: any, canOperate: boolean, status: number }) => {
                    const strTitle = item.name;
                    const index = strTitle.indexOf(searchValue);
                    const beforeStr = strTitle.substring(0, index);
                    const afterStr = strTitle.slice(index + searchValue.length);
                    const name
                  = index > -1 ? (
                      <span>
                          {beforeStr}
                          <span style={{color: '#2468f2'}}>{searchValue}</span>
                          {afterStr}
                      </span>
                  ) : (
                      <span>{strTitle}</span>
                  );
                    if (item.children) {
                        return {...item, name, id: item.id, children: loop(item.children)};
                    }

                    return {
                        ...item,
                        name,
                        id: item.id,
                    };
                });
            };
            return loop(filterOrgTree);
        }, [searchValue, orgTree]);
        const flatTreeListCurrent = useMemo(() => {
            return getFlatTreeList(treeData);
        }, [treeData]);
        const currentOrgInTree = useMemo(() => {
            return flatTreeListCurrent.some(_ => _.id === currentOrg.id);
        }, [treeData]);

        const onExpand = (newExpandedKeys: React.Key[]) => {
            setExpandedKeys(newExpandedKeys);
        };
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const {value} = e.target;
            const newExpandedKeys = flatTreeList
                .map((item: { name: string | string[], id: React.Key }) => {
                    if (item.name.includes(value)) {
                        return getParentKey(item.id, orgTree);
                    }
                    return null;
                })
                .filter((item: any, i: any, self: string | any) => item && self.indexOf(item) === i);
            setExpandedKeys(newExpandedKeys as React.Key[]);
            setSearchValue(value);
        };
        useEffect(() => {
            setExpandedKeys(flatTreeList.map(_ => _.id));
        }, []);
        return (
            <TreeSelect
                style={{marginLeft: '16px', fontSize: '12px', width: 130}}
                value={currentOrgInTree ? currentOrg.id : undefined}
                placeholder={(currentOrg as OrgItem).name}
                treeDefaultExpandAll={true}
                onChange={switchOrg}
                dropdownMatchSelectWidth={260}
                onTreeExpand={onExpand}
                treeExpandedKeys={expandedKeys}
                treeData={treeData}
                fieldNames={{
                    label: 'name',
                    value: 'id',
                }}
                suffixIcon={<Icon style={{pointerEvents: 'none'}} component={() =>
                    <ArrowDownIcon
                        src={arrowDown}
                    ></ArrowDownIcon>
                }></Icon>}
                switcherIcon={<DownOutlined style={{color: '#84868C'}} />}
                dropdownRender={(originNode: any) => (
                    <DropdownWrapper>
                        <div className="recent-orgs">
                            <MenuSearchContainer
                                onChange={onChange}
                            ></MenuSearchContainer>
                            {
                                !!currentOperateOrgList?.length && <div className="history">历史记录</div>
                            }
                            <Space size={8} wrap>
                                {(currentOperateOrgList || []).slice(0, 4).map(org => (
                                    <Popover key={org.id} content={org.status ? '该机构不可用' : ''}>
                                        <Button
                                            disabled={!!org.status}
                                            type="link"
                                            key={org.id}
                                            onClick={() => switchOrg(org.id)}
                                        >
                                            {org.name}
                                        </Button>
                                    </Popover>
                                ))}
                            </Space>
                        </div>
                        {originNode}
                    </DropdownWrapper>
                )}
            >
            </TreeSelect>
        );
    }
    return null;
};

