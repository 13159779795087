
// 商品类型枚举
export enum CommodityTypeEnum {
    service = 1,
    commodity = 2,
    food = 4,
    takeaway = 6,
    medical = 32,
}
export enum CommodityKeyEnum {
    SERVICE = 'service',
    GOODS = 'goods',
    FOOD = 'food',
    TAKEAWAY = 'takeaway',
    MEDICAL = 'medical'
}
// 商品类型列表
export const CommodityTypeList = [
    {label: '服务', value: CommodityTypeEnum.service, key: CommodityKeyEnum.SERVICE},
    {label: '商品', value: CommodityTypeEnum.commodity, key: CommodityKeyEnum.GOODS},
    {label: '餐品', value: CommodityTypeEnum.food, key: CommodityKeyEnum.FOOD},
    {label: '外卖', value: CommodityTypeEnum.takeaway, key: CommodityKeyEnum.TAKEAWAY},
    {label: '医疗', value: CommodityTypeEnum.medical, key: CommodityKeyEnum.MEDICAL},
];


// 商品状态
export enum StatusType {
    online = 1,
    offline = 2,
    all = 0,
}
// 商品模板状态列表
export const StatusList = [
    {
        text: '全部状态',
        value: StatusType.all,
    },
    {
        text: '上架',
        value: StatusType.online,
    },
    {
        text: '下架',
        value: StatusType.offline,
    },
];

// 站点商品列表tab
export const StationStatusList = [
    {
        text: '商品售卖列表',
        value: StatusType.online,
    },
    {
        text: '商品进货列表',
        value: StatusType.offline,
    },
];

export const BuyLimitTypeList = [
    {text: '不限制', value: 0},
    {text: '按天限制', value: 1},
    {text: '总数限制', value: 2},
];

export enum Mealtime {
    breakfast = 1,
    lunch = 2,
    dinner = 4,
}

export const MealTimes = [
    {
        label: '早餐',
        value: Mealtime.breakfast,
    },
    {
        label: '午餐',
        value: Mealtime.lunch,
    },
    {
        label: '晚餐',
        value: Mealtime.dinner,
    },
];

interface CategoryObjType {
    [props: string]: {
        name: string;
        val: number;
    };
}

export const CategoryObj: CategoryObjType = {
    service: {
        name: '服务',
        val: CommodityTypeEnum.service,
    },
    goods: {
        name: '商品',
        val: CommodityTypeEnum.commodity,
    },
    food: {
        name: '餐品',
        val: CommodityTypeEnum.food,
    },
    takeaway: {
        name: '外卖',
        val: CommodityTypeEnum.takeaway,
    },
    medical: {
        name: '医疗',
        val: CommodityTypeEnum.medical,
    },
};


export enum ServiceType {
    shop = 1,
    door = 2,
}
export const ServiceList = [
    {
        name: '到店',
        val: ServiceType.shop,
    },
    {
        name: '上门',
        val: ServiceType.door,
    },
];
