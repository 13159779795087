/*
 * @Author: shiruiqiang
 * @Date: 2023-07-30 20:22:41
 * @LastEditTime: 2023-12-19 15:46:24
 * @LastEditors: shiruiqiang
 * @FilePath: pay.ts
 * @Description: shiruiqiang
 */

export enum PayType {
    OnlinePay = 2,
    OfflinePay = 1,
    WxPay = 3,
}

export const payList = [
    {
        name: '在线支付',
        val: PayType.OnlinePay,
        color: 'green',
    },
    {
        name: '线下支付',
        val: PayType.OfflinePay,
        color: 'geekblue',
    },
    {
        name: '微信支付',
        val: PayType.WxPay,
        color: 'volcano',
    },
];


/**
 * 08.02 运营监管 2.01 迭代
 * 用于订单列表展示详细的支付方式,与上面暂时不冲突
 */
export enum ListPayTypeEnum {
    // 线下支付 or 现金支付   （以后就不用了）
    OrderPayOffline = 1,
    // 积分支付
    PayPoint = 2,
    // 微信支付
    PayTradeCenter = 3,
    // 网格员余额支付
    PayGridAmount = 4,
    // 饭卡支付
    PayCardNo = 5,
    // 现金支付
    PayCash = 6,
    // 人脸支付
    PayFace = 7
}
export const ListPayType = [
    {
        name: '线下支付',
        val: ListPayTypeEnum.OrderPayOffline,
        color: 'red',
    },
    {
        name: '积分支付',
        val: ListPayTypeEnum.PayPoint,
        color: 'green',
    },
    {
        name: '微信支付',
        val: ListPayTypeEnum.PayTradeCenter,
        color: 'geekblue',
    },
    {
        name: '网格员余额支付',
        val: ListPayTypeEnum.PayGridAmount,
        color: 'volcano',
    },
    {
        name: '饭卡支付',
        val: ListPayTypeEnum.PayCardNo,
        color: 'cyan',
    },
    {
        name: '现金支付',
        val: ListPayTypeEnum.PayCash,
        color: 'gold',
    },
    {
        name: '人脸支付',
        val: ListPayTypeEnum.PayFace,
        color: 'purple',
    },
];

export enum BankCardTypeEnum {
    ToPublic = 1,
    ToPrivate = 2,
}

export const BankCardTypeMap = [
    {value: BankCardTypeEnum.ToPublic, label: '对公'},
    {value: BankCardTypeEnum.ToPrivate, label: '对私'},
];
