/*
 * @Author: shiruiqiang
 * @Date: 2023-11-07 19:01:33
 * @LastEditTime: 2023-12-12 14:52:09
 * @LastEditors: shiruiqiang
 * @FilePath: router-map.tsx
 * @Description: shiruiqiang
 */
import React from 'react';
import {NavLink} from 'react-router-dom';
import {Badge} from 'antd';
import Icon from '@ant-design/icons';
import {Modules} from 'app/constants/moduleIds';
import {useOrderModel} from 'app/models';
import {
    ElderIcon,
    SubOrgIcon,
    ActivityIcon,
    NoticeIcon,
    ServiceManageIcon,
    CallRecordsIcon,
    NewsIcon,
    VideoClassIcon,
    IotIcon,
    GirdManIcon,
} from 'app/assets/icon';

export interface MenuItem {
    name?: string;
    eventKey: string;
    linkTo: string;
    permissionKeys?: number[];
    collateralPermissionKeys?: number[];
    nestedComponent?: () => React.ReactNode;
    isDisplay?: (fn: () => boolean) => boolean;
}
export interface MenuRouteItem {
    name: string;
    eventKey: string;
    permissionKeys: number[];
    collateralPermissionKeys?: number[]; // 当用户拥有相应权限时，可以访问该模块的页面，但该模块不会在菜单中显示。
    icon: React.ReactNode;
    linkTo?: string;
    nestedComponent?: () => React.ReactNode;
    menuItem?: MenuItem[];
    isDisplay?: (fn: () => boolean) => boolean;
}

export const GovDynamicRouteMenuMap: MenuRouteItem[] = [
    {
        name: '组织管理',
        eventKey: '/orgs',
        permissionKeys: [Modules.OrganizationManage.id, Modules.StaffManage.id],
        icon: <Icon component={SubOrgIcon} />,
        menuItem: [
            {
                name: '组织管理',
                eventKey: '/orgs',
                linkTo: '/orgs',
                permissionKeys: [Modules.OrganizationManage.id],
            },
            {
                name: '组织人员管理',
                eventKey: '/users',
                linkTo: '/users',
                permissionKeys: [Modules.StaffManage.id],
            },
        ],
    },
    {
        name: '用户管理',
        eventKey: '/elder',
        permissionKeys: [Modules.UserManage.id],
        icon: <Icon component={ElderIcon} />,
        menuItem: [
            {
                name: '用户管理',
                eventKey: '/elder/list',
                linkTo: '/elder/list',
                permissionKeys: [Modules.UserManage.id],
            },
            {
                name: '用户标签管理',
                eventKey: '/elder/types',
                linkTo: '/elder/types',
                permissionKeys: [Modules.UserTypeManage.id],
            },
        ],
    },
    {
        name: '通话管理',
        eventKey: '/newphonebook',
        permissionKeys: [Modules.NewCustomerCenter.id, Modules.CustomerCenter.id],
        icon: <Icon component={CallRecordsIcon} />,
        menuItem: [
            {
                name: '通讯录管理',
                eventKey: '/phonebook',
                linkTo: '/phonebook',
                permissionKeys: [Modules.CustomerCenter.id],
            },
            {
                name: '通讯录管理',
                eventKey: '/newphonebook',
                linkTo: '/newphonebook',
                permissionKeys: [Modules.NewCustomerCenter.id],
            },
            {
                name: '通话记录',
                eventKey: '/callrecords',
                linkTo: '/callrecords',
                permissionKeys: [Modules.NewCustomerCenter.id, Modules.CustomerCenter.id],
            },
        ],
    },
    {
        name: '政令直达',
        eventKey: '/notice',
        permissionKeys: [Modules.NoticeManage.id, Modules.Signature.id],
        icon: <Icon component={NoticeIcon} />,
        menuItem: [
            {
                name: '政令直达管理',
                eventKey: '/notice',
                linkTo: '/notice',
            },
            {
                name: '政令标签管理',
                eventKey: '/signature',
                linkTo: '/signature',
            },
        ],
    },
    {
        name: '政府服务',
        eventKey: '/service',
        permissionKeys: [Modules.ServiceManage.id],
        icon: <Icon component={ServiceManageIcon} />,
        menuItem: [
            {
                name: '服务类型',
                eventKey: '/service/category',
                linkTo: '/service/category',
            },
            {
                name: '服务管理',
                eventKey: '/service',
                linkTo: '/service',
            },
            {
                name: '服务订单',
                eventKey: '/service/order',
                linkTo: '/service/order',
                permissionKeys: [Modules.ServiceOrder.id],
                nestedComponent: () => {
                    const orderinfo = useOrderModel();
                    return <NavLink to="/service/order">
                        <Badge dot={!!orderinfo.service} size="small" offset={[5, 10]} overflowCount={99}>
                        服务订单
                        </Badge>
                    </NavLink>;
                },
            },
        ],
    },
    {
        name: '社区要闻',
        eventKey: '/news',
        permissionKeys: Modules.AllNewsModules.ids,
        icon: <Icon component={NewsIcon} />,
        menuItem: [
            {
                name: '要闻管理',
                eventKey: '/news',
                linkTo: '/news',
                permissionKeys: [Modules.NewsManage.id],
            },
            {
                name: '要闻类型',
                eventKey: '/newstype',
                linkTo: '/newstype',
                permissionKeys: [Modules.NewsTypeManage.id],
            },
        ],
    },
    {
        name: '社区视频',
        eventKey: '/videoclass',
        permissionKeys: Modules.AllVideoClassModules.ids,
        icon: <Icon component={VideoClassIcon} />,
        menuItem: [
            {
                name: '视频管理',
                eventKey: '/videoclass',
                linkTo: '/videoclass',
                permissionKeys: [Modules.VideoClassManage.id],
            },
            {
                name: '视频类型',
                eventKey: '/videoclasstype',
                linkTo: '/videoclasstype',
                permissionKeys: [Modules.VideoClassTypeManage.id],
            },
        ],
    },
    {
        name: '惠民活动',
        eventKey: '/activity',
        permissionKeys: [Modules.ActivityManage.id],
        icon: <Icon component={ActivityIcon} />,
        menuItem: [
            {
                name: '惠民活动管理',
                eventKey: '/activity',
                linkTo: '/activity',
            },
        ],
    },
    {
        name: '智能监测',
        eventKey: '/smartmonitor',
        permissionKeys: [Modules.IotDevice.id],
        icon: <Icon component={IotIcon} />,
        menuItem: [
            {
                name: '智能报警',
                eventKey: '/alarm',
                linkTo: '/alarm',
            },
        ],
    },
    {
        name: '社区治理',
        eventKey: '/gridman',
        permissionKeys: [Modules.GridmanManage.id],
        icon: <Icon component={GirdManIcon} />,
        menuItem: [
            {
                name: '微网格长管理',
                eventKey: '/gridman/user',
                linkTo: '/gridman/user',
            },
            {
                name: '治理规则管理',
                eventKey: '/gridman/entry/list',
                linkTo: '/gridman/entry/list',
            },
            {
                name: '事件管理',
                eventKey: '/gridman/eventProcess/list',
                linkTo: '/gridman/eventProcess/list',
            },
            {
                name: '网格员积分管理',
                eventKey: '/gridman/points',
                linkTo: '/gridman/points',
            },
            {
                name: '网格员排名',
                eventKey: '/gridman/ranking',
                linkTo: '/gridman/ranking',
            },
            {
                name: '数据统计',
                eventKey: '/gridman/statistic',
                linkTo: '/gridman/statistic',
            },
            {
                name: '兑换台账',
                eventKey: '/gridman/standingbook/exchange',
                linkTo: '/gridman/standingbook/exchange',
            },
        ],
    },
    {
        name: '供应商管理',
        eventKey: '/supplier',
        permissionKeys: [Modules.SupplierManage.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/supplier',
    },
    {
        name: '直播管理',
        eventKey: '/live',
        permissionKeys: [Modules.LiveManage.id],
        icon: <Icon component={NewsIcon} />,
        linkTo: '/live',
    },
    {
        name: '台账总览',
        eventKey: '/supplier/billing',
        permissionKeys: [Modules.BillingProfile.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/supplier/billing',
    },
    {
        name: '消费台账',
        eventKey: '/consume/standingbook',
        permissionKeys: [Modules.ConsumeStandindBook.id, Modules.DistributionStandindBook.id, Modules.CommissionStandindBook.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/consume/standingbook',
        menuItem: [
            {
                name: '供应商台账',
                eventKey: '/consume/standingbook/supplier',
                permissionKeys: [Modules.ConsumeStandindBook.id],
                linkTo: '/consume/standingbook/supplier',
            },
            {
                name: '分成台账',
                eventKey: '/consume/standingbook/distribution',
                permissionKeys: [Modules.DistributionStandindBook.id],
                linkTo: '/consume/standingbook/distribution',
            },
            {
                name: '抽成台账',
                eventKey: '/consume/standingbook/brokerage',
                permissionKeys: [Modules.CommissionStandindBook.id],
                linkTo: '/consume/standingbook/brokerage',
            },
        ],
    },
    {
        name: '结算台账',
        eventKey: '/closeaccount/standingbook',
        permissionKeys: [Modules.CloseAccountStandindBook.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/closeaccount/standingbook',
    },
];
