
import {Modal, message} from 'antd';
import {ApiResponse, post, postApi} from 'app/utils/fetch';
import {sentrySevice} from 'app/utils/sentry';
import {useCallback, useEffect, useRef, useState} from 'react';
import {LoginResponse, OrgItem} from 'proto/login';
import {PlatformStore} from '..';
import {PlatformTypeEnum} from 'app/constants';
export type UserInfo = LoginResponse;

const CURRENT_ORG_ID = 'CURRENT_ORG_ID';
export const useUserInfo = () => {
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const {platform} = PlatformStore.usePlatform();
    const {currentOrg = {id: -1}} = userInfo || {};
    const [refresh, setRefresh] = useState(false);
    const [isFetch, setIsFetch] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const [hasPermission, setPermission] = useState(false);
    const orgSwitchConfimRef = useRef<{destroy: () => void}>();
    useEffect(() => {
        const {getCurrentHub, finish} = sentrySevice({name: '/visitCMS'});
        post<ApiResponse<UserInfo>>('/employee/login/info', {
            fromGov: platform === PlatformTypeEnum.GOV,
        })
            .then(res => {
                const {data, error_code} = res;
                if (error_code === 0 && data && data.id) {
                    setUserInfo({
                        ...data,
                        currentOrg: data.currentOrg || ({} as unknown as OrgItem),
                        modules: data.modules || [],
                        orgTree: data.orgTree || [],
                        currentOperateOrgList: data.currentOperateOrgList || [],
                        subModules: data.subModules || [],
                    });
                    setIsLogin(true);
                    setPermission(true);
                    getCurrentHub().setTag('uid', data.id);
                    getCurrentHub().setTag('orgId', data.currentOrg?.id);
                    getCurrentHub().setTag('orgName', data.currentOrg?.name);
                }
                else if (error_code === 1000007) {
                    setUserInfo({
                        ...data,
                        currentOrg: data.currentOrg || ({} as unknown as OrgItem),
                        modules: data.modules || [],
                        orgTree: data.orgTree || [],
                        currentOperateOrgList: data.currentOperateOrgList || [],
                        subModules: data.subModules || [],
                    });
                    setIsLogin(true);
                    setPermission(false);
                }
            })
            .finally(() => {
                setIsFetch(false);
                finish();
            });
    }, [refresh]);
    const orgListener = useCallback(
        (ev: StorageEvent) => {
            if (ev.key === CURRENT_ORG_ID) {
                if (userInfo && userInfo.currentOrg && userInfo.currentOrg.id !== Number(ev.newValue)) {
                    if (orgSwitchConfimRef.current) {
                        orgSwitchConfimRef.current.destroy();
                    }
                    //  org is change
                    orgSwitchConfimRef.current = Modal.confirm({
                        title: '系统感知您已在其他页面操作切换操作机构，请问当前页面是否需要同步切换？',
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                            // 切换直接刷新
                            setRefresh(r => !r);
                        },
                        onCancel: () => {
                            actions.switchOrg(currentOrg.id);
                        },
                    });
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userInfo]
    );
    useEffect(() => {
        if (userInfo) {
            window.addEventListener('storage', orgListener);
            return () => window.removeEventListener('storage', orgListener);
        }
    }, [userInfo, orgListener]);
    const actions = {
        /**
         * 切换机构
         * @param orgId
         */
        switchOrg: (orgId: number) => {
            return postApi('/organization/switch', {orgId})
                .then(() => {
                    window.localStorage.setItem(CURRENT_ORG_ID, orgId + '');
                    setRefresh(r => !r);
                })
                .catch(err => {
                    message.error(err.message);
                    throw err;
                });
        },
        /**
         * 登录
         * @param path
         */
        logIn: (path?: string) => {
            window.location.href
                = '/login?backname=' + encodeURIComponent(path || window.location.origin);
        },
        /**
         * 登出
         * @param path
         */
        logOut: (path: string) => {
            postApi('/logout', {})
                .then(() => {
                    const logoutEl = new Image();
                    logoutEl.style.display = 'none';
                    logoutEl.src = '';
                    logoutEl.onerror = () => {
                        window.location.href = encodeURIComponent(path) || '/';
                    };
                })
                .catch(err => {
                    console.log(err.message);
                });
        },
        refresh: () => {
            setRefresh(r => !r);
        },
    };

    return {
        userInfo,
        isFetch,
        isLogin,
        hasPermission,
        orgId: userInfo && userInfo.currentOrg ? userInfo.currentOrg.id : -1,
        orgName: userInfo && userInfo.currentOrg ? userInfo.currentOrg.name : '',
        orgType: userInfo && userInfo.currentOrg ? userInfo.currentOrg.orgType : -1,
        isRootOrg: userInfo && userInfo.currentOrg ? !!userInfo.orgTree.find(o => o.id === userInfo.currentOrg?.id) : false,
        actions,
    };
};
