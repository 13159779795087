import React, {createContext, useCallback, lazy, useRef, useState, useEffect} from 'react';
import {useRootModel, useLayoutModel} from 'app/models';
import {Modules} from '../../constants/moduleIds';
import {message, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
const Caller = lazy(() => import('app/components/caller'));
const CustomCaller = lazy(() => import('app/components/custom-caller'));
import {useLocation} from 'react-router-dom';

const {confirm} = Modal;

export const CallerContext = createContext({
    makeCall: (id: number, name: string) => {},
    updateCallCenter: (id?: number) => {},
    login: () => {},
    logout: () => {},
    isLogin: false,
    setLogin: (login: boolean) => {},
});

export const CallerProvider = ({children, unLogin}: {children: JSX.Element, unLogin?: boolean}) => {
    const callerRef = useRef<any>();
    const [isLogin, setLogin] = useState(false);
    const {userInfo, isLogin: userIsLogin} = useRootModel();
    const {modules = [], currentOrg} = userInfo || {};
    const orgId = currentOrg?.id || 0;
    const isNew = !!modules?.find((item: any) => item.id === Modules.NewCustomerCenter.id);
    const hasPermission = modules?.filter(item => item.id === Modules.CustomerCenter.id || item.id === Modules.NewCustomerCenter.id).length > 0;
    const [mountCall, setMountCall] = useState(false);
    const location = useLocation();
    const {isolated} = useLayoutModel() || {};

    useEffect(() => {
        localStorage.setItem('isCallCenterLogin', isLogin.toString());
    }, [isLogin]);

    const handleUpdate = useCallback((id?: number) => {
        mountCall && callerRef.current.update(id);
    }, [mountCall]);

    const login = () => {
        callerRef.current.login();
    };

    const logout = () => {
        callerRef.current.logout();
    };

    const handleCallerReady = () => {
        const hasRoute = (_: string) => location.pathname.includes(_);
        if (['/live/stream', '/live/playback'].some(hasRoute) || isolated) {
            return;
        }
        const islogin = localStorage.getItem('isCallCenterLogin');
        // 自动登录
        if (!unLogin && userIsLogin && hasPermission && islogin === 'false') {
            login();
        }
    };

    const makeCall = useCallback(
        (id: number, name: string) => {
            if (!isLogin) {
                confirm({
                    title: '提示',
                    icon: <ExclamationCircleOutlined />,
                    content: '当前客服未上线，上线后即可发起呼叫。请您确定是否上线并发起呼叫。',
                    onOk() {
                        if (isNew) {
                            callerRef.current.makeCall(id, name, orgId);
                        }
                        else {
                            callerRef.current.login(() => {
                                callerRef.current.makeCall(id, name, orgId);
                            });
                        }
                    },
                    onCancel() {},
                });
            } else {
                callerRef.current.makeCall(id, name, orgId);
            }
        },
        [isLogin, orgId]
    );

    useEffect(() => {
        setMountCall(true);
    }, []);
    return (
        <CallerContext.Provider
            value={{
                makeCall: makeCall,
                updateCallCenter: handleUpdate,
                isLogin,
                setLogin,
                login,
                logout,
            }}
        >
            {children}
            {
                mountCall && (
                    isNew ? (
                        <CustomCaller ref={callerRef} ready={handleCallerReady} />
                    ) : (
                        <Caller ref={callerRef} ready={handleCallerReady} />
                    )
                )
            }
        </CallerContext.Provider>
    );
};
