
import React from 'react';
import styled from 'styled-components';
import {Tag} from 'antd';

const TagWrap = styled.p`
    .ant-tag {
        width: 80px;
        line-height: 24px;
        border-radius: 16px;
        border: none;
        font-size: 14px;
        padding: 0 12px;
        white-space: pre;
        cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
        span {
            vertical-align: middle;
        }
        .dot {
            font-size: 20px;
            position: relative;
            top: -1px;
        }
    }
`;

interface TagProps {
    name: string;
    color: string;
    onClick?: () => void;
}

export function StatusTag({color, name, onClick}: TagProps) {
    const clickCb = onClick ?? (() => {});
    return (
        <TagWrap onClick={onClick}>
            <Tag color={color} onClick={clickCb}>
                <span className="dot">•</span> <span>{name || ''}</span>
            </Tag>
        </TagWrap>
    );
}

const AcudTagWrap = styled.span`
    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        display: inline-block;
    }
`;
export function AcudTag({name, color = '#30BF13', ...rest}: { name: string, color: string }) {
    return (
        <AcudTagWrap {...rest}>
            <span className="dot" style={{background: color}}></span>
            <span>{name}</span>
        </AcudTagWrap>
    );
}
