import React, {createContext, useCallback, lazy, useRef, useState, useEffect} from 'react';
import {useRootModel} from 'app/models';
import {Modules} from '../../constants/moduleIds';
import {message, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
const DoctorCaller = lazy(() => {
    return import('app/pages/familyDoctor/onlineConsultation/appointmentList/components/ConsultationCaller/index');
});
import {useLocation} from 'react-router-dom';
import {postApi} from 'app/utils/fetch';
import {DoctorInfoItem, DoctorCurrentInfoRequest} from 'proto/family_doctor';

interface UserField {
    customerID: number;
    customerName: string;
}

interface DoctorField {
    doctorID: number;
    doctorEmployeeName: string;
}

const FOREVERLOGINLOCAL = 'isFamilyDoctorLogin';

export const DoctorContext = createContext<{
    makeCall: (user: UserField, doctor: DoctorField, bookId: number) => void;
    login: () => void;
    logout: () => void;
    isLogin: boolean;
    setLogin: (login: boolean) => void;
    handleForeverLogin: (yesOrNo: boolean) => void;
    doctorCallAddress: number;
    LocalLoginId: string;
    currentDoctor: DoctorInfoItem | null;
    mount: boolean;
    setMount: (mount: boolean) => void;
    refreshState: boolean;
    setRefreshState: (refreshState: boolean) => void;
        }>({
            makeCall: (user: UserField, doctor: DoctorField, bookId: number) => {},
            login: () => {},
            logout: () => {},
            isLogin: false,
            setLogin: (login: boolean) => {},
            handleForeverLogin: (yesOrNo: boolean) => {},
            doctorCallAddress: 0,
            LocalLoginId: FOREVERLOGINLOCAL,
            currentDoctor: null,
            mount: false,
            setMount: (mount: boolean) => {},
            refreshState: false,
            setRefreshState: (refreshState: boolean) => {},
        });

export const DoctorProvider = ({children, unLogin}: {children: JSX.Element, unLogin?: boolean}) => {
    const callerRef = useRef<any>();
    const [isLogin, setLogin] = useState(false);
    const {userInfo, isLogin: userIsLogin} = useRootModel();
    const {currentOrg} = userInfo || {};
    const orgId = currentOrg?.id || 0;
    const [mountCall, setMountCall] = useState(false);
    const [doctorCallAddress, setDoctorCallAddress] = useState(0);
    const [currentDoctor, setCurrentDoctor] = useState<DoctorInfoItem | null>(null);
    const [mount, setMount] = useState(false);
    const [refreshState, setRefreshState] = useState(false);

    const login = () => {
        callerRef.current.login();
    };

    const logout = () => {
        callerRef.current.logout();
    };

    const handleCallerReady = () => {
        const needAutoLogin = localStorage.getItem(FOREVERLOGINLOCAL);
        // 自动登录
        if (!isLogin && userIsLogin && needAutoLogin === 'true') {
            login();
        } else {
            setMount(true);
        }
    };

    const handleForeverLogin = (yesOrNo: boolean) => {
        localStorage.setItem(FOREVERLOGINLOCAL, yesOrNo.toString());
    };

    const makeCall = useCallback(
        (user: UserField, doctor: DoctorField, bookId: number) => {
            if (isLogin) {
                callerRef.current.makeCall(user, doctor, bookId);
            }
        },
        [isLogin]
    );

    // 获取当前登录医生信息
    const getCurrentDoctor = () => {
        postApi<DoctorInfoItem, DoctorCurrentInfoRequest>('/family-doctor/doctor/current-info', {
            orgID: orgId,
        }).then(res => {
            setCurrentDoctor(res || null);
            setDoctorCallAddress(res?.callAddress || 0);

            if (res?.callAddress) {
                setTimeout(() => {
                    handleCallerReady();
                }, 300);
            } else {
                setMount(true);
            }
        }).catch(() => setMount(true));
    };

    useEffect(() => {
        setMountCall(true);
    }, []);
    return (
        <DoctorContext.Provider
            value={{
                makeCall: makeCall,
                isLogin,
                setLogin,
                login,
                logout,
                handleForeverLogin,
                doctorCallAddress,
                LocalLoginId: FOREVERLOGINLOCAL,
                currentDoctor,
                mount,
                setMount,
                refreshState,
                setRefreshState,
            }}
        >
            {children}
            {
                mountCall && <DoctorCaller
                    ref={callerRef}
                    ready={getCurrentDoctor}
                    doctorCallAddress={doctorCallAddress}
                ></DoctorCaller>
            }
        </DoctorContext.Provider>
    );
};
