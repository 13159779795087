import React, {useContext, useState} from 'react';
import {useRootModel, useHeadertab, useLayoutModel} from 'app/models';
import {Menu, Button, Dropdown, Tooltip, Space, Tabs, Statistic} from 'antd';
import {LineChartOutlined} from '@ant-design/icons';
import {Modules} from 'app/constants/moduleIds';
import {PermissionGuard} from 'app/components/guard';
import {AcudTag} from 'app/components/tag';
import {CallerContext} from 'app/models/caller';
import earphoneIcon from 'app/assets/icon/earphone.svg';
import dataStaticIcon from 'app/assets/icon/data-static.svg';
import {Link, NavLink, useHistory} from 'react-router-dom';
import {OrgSelector} from 'app/components';
import styled from 'styled-components';
import './index.scss';

const {Item} = Menu;

const Head = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #8e868c;
    display: flex;
    justify-content: center;
    algin-items: center;
    color: #ffffff;
`;

export default ({type}: {type?: string}) => {
    const {isLogin, userInfo, isFetch, actions, hasPermission} = useRootModel();
    const {isLogin: callerLogin, login, logout} = useContext(CallerContext);
    const {name, currentOrg, modules, systemName} = userInfo || {};
    const {name: orgName} = currentOrg || {};
    const showTooltip = !localStorage.getItem('dontTooltip');
    const [showTip, setShowTip] = useState(showTooltip);
    const hasSupervisePermission = !!modules?.find(item => item.id === Modules.DataStatistics.id);
    const {activeTabKey, changeActiveTabKey, TabOptions} = useHeadertab();
    const {isolated} = useLayoutModel() || {};
    const platformName = systemName ? systemName : type === 'gov' ? '政府监管平台' : 'AI社区服务平台';
    return (
        <div className="header-wrap">
            <a style={{display: 'flex', alignItems: 'center'}}>
                <img src={(window as any).LogoSrc} style={{height: 28}} />
            </a>
            <span className="header-title">{platformName}</span>
            <div style={{flex: 1, display: 'flex', alignItems: 'center', height: '100%', gap: '24px'}}>
                <OrgSelector></OrgSelector>
                {
                    !isolated && (
                        <Tabs
                            activeKey={activeTabKey}
                            items={TabOptions}
                            onChange={v => changeActiveTabKey(v)}
                        ></Tabs>
                    )
                }
            </div>
            {!isFetch && (
                <>
                    {hasPermission && isLogin && !isolated && <>
                        {
                            type !== 'gov' && <PermissionGuard keys={[Modules.BusinessData.id]}>
                                <div className="data-all">
                                    <Link to="/dataall" target="_blank">
                                        <img src={dataStaticIcon} className="icon"></img>
                                        <span>数据看板</span>
                                    </Link>
                                </div>
                            </PermissionGuard>
                        }
                        {
                            type === 'gov' && hasSupervisePermission && <>
                                <div className="data-all">
                                    <Link to="/dataall" target="_blank">
                                        <img src={dataStaticIcon} className="icon"></img>
                                        <span>数据驾驶舱</span>
                                    </Link>
                                </div>
                            </>
                        }
                        <Tooltip
                            color="#2468F2"
                            placement="bottom"
                            overlayClassName="header-tooltip"
                            title={() => {
                                return (
                                    <div className="tooltip">
                                        <p>上线后即可拨打接听电话</p>
                                        <div>
                                            <Space>
                                                <span
                                                    className="btn"
                                                    onClick={() => {
                                                        localStorage.setItem('dontTooltip', 'true');
                                                        setShowTip(false);
                                                    }}
                                                >
                                                不再提示
                                                </span>
                                                <span
                                                    className="btn"
                                                    onClick={() => {
                                                        setShowTip(false);
                                                    }}
                                                >
                                                关闭
                                                    {
                                                        showTip && (
                                                            <Statistic.Countdown
                                                                format="ss"
                                                                value={Date.now() + 10 * 1000}
                                                                prefix="("
                                                                suffix=")"
                                                                valueStyle={{
                                                                    fontSize: 12,
                                                                    color: '#ffffff',
                                                                }}
                                                                onFinish={() => setShowTip(false)}
                                                            ></Statistic.Countdown>
                                                        )
                                                    }
                                                </span>
                                            </Space>
                                        </div>
                                    </div>
                                );
                            }}
                            open={showTip}
                        >
                            <div className="callcenter">
                                {callerLogin ? (
                                    <>
                                        <AcudTag color="#30BF13" name="客服已上线" />
                                        <Tooltip
                                            title="离线后将不能接听电话"
                                            color="#fff"
                                            overlayClassName="callcenter-tooltip"
                                        >
                                            <p className="operator" onClick={logout}>
                                                <img src={earphoneIcon} className="icon" />
                                                <span>一键离线</span>
                                            </p>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <AcudTag color="#B8BABF" name="客服离线中" />
                                        <Tooltip
                                            title="上线后即可接听电话"
                                            color="#fff"
                                            overlayClassName="callcenter-tooltip"
                                        >
                                            <p className="operator" onClick={() => login()}>
                                                <img src={earphoneIcon} className="icon" />
                                                <span>一键上线</span>
                                            </p>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        </Tooltip>
                    </>}
                    <div className="dropdown">
                        {isLogin ? (
                            <Dropdown
                                placement="bottomRight"
                                overlay={
                                    <Menu>
                                        <Item key="name">
                                            <Space size={8} style={{width: '240px', padding: '6px 0'}}>
                                                <Head>{name?.slice(0, 1)}</Head>
                                                <div className="name">{name}</div>
                                            </Space>
                                        </Item>
                                        <Item key="quit">
                                            <p
                                                style={{textAlign: 'center', padding: '6px 0', borderTop: '1px solid #f7f7f9'}}
                                                onClick={() => actions.logOut(window.location.origin)}
                                            >退出</p>
                                        </Item>
                                    </Menu>
                                }
                            >
                                <span className="username">
                                    {orgName && <span>{orgName + ' • '}</span>}
                                    {name}
                                </span>
                            </Dropdown>
                        ) : (
                            <a href="/login">登录</a>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
