import {PlatfromConfig} from '../constants/platform';
export const defaultPrefix = '/api';

function fetchUrl(url: string, options: any = {}, prefix = defaultPrefix) {
    if (!url) {
        return Promise.reject(new Error('no url'));
    }
    if (!url.startsWith('http')) {
        url = prefix + url;
    }
    if (!options.ignoreCredentials) {
        options.credentials = 'include';
    }

    return fetch(url, options).then(d => (d ? d.json() : null));
}

export const post = <T = any>(url: string, data: any, customHeader?: object) => {
    const headers: Record<string, any> = {
        'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT',
        'x-edulogin-from': 'eldercms',
    };
    let body: string = '';
    if (typeof data === 'string') {
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        body = data;
    } else {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(data || {});
    }
    headers['from-platform'] = PlatfromConfig.currentPlatform;
    const finalHeaders = {
        ...headers,
        ...(customHeader || {}),
    };
    return fetchUrl(url, {
        method: 'POST',
        headers: finalHeaders,
        body,
    }) as Promise<T>;
};
export const postApi = <T = any, P = any>(url: string, data?: P, customHeader?: object) => {
    return post<ApiResponse<T>>(url, data, customHeader).then(e => {
        if (e.error_code !== 0) {
            throw new Error(e.error_message);
        }
        return e.data;
    });
};

export const getQuery = (search: string, name: string) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const r = search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

export default fetchUrl;

export interface ApiResponse<T> {
    data: T;
    error_code: number;
    error_message: string;
}
