/**
* @file index.tsx
* @author pankairan
* @date 2022-06-07 14:24
*/
import {useRootModel} from 'app/models';
import React, {PropsWithChildren} from 'react';

type PermissionGuardProps = PropsWithChildren<{
    keys?: number[];
    fallback?: React.ReactNode;
}>;
export const PermissionGuard = (props: PermissionGuardProps) => {
    const {keys = []} = props;
    const {userInfo} = useRootModel();
    const {modules = []} = userInfo || {};
    return <>
        {modules.find(x => keys.includes(x.id)) ? props.children : props.fallback}
    </>;
};
