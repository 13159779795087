import React from 'react';
import {Input, InputProps} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {PlatformStore} from 'app/models';
import {PlatformTypeEnum} from 'app/constants';
import './index.scss';

const MenuSearchContainer = (props: InputProps) => {
    const {platform} = PlatformStore.usePlatform();
    return <div className={`menu-search-container ${platform === PlatformTypeEnum.GOV ? 'gov' : ''}`}>
        <Input
            style={{marginBottom: '8px', padding: 0}}
            placeholder="搜索"
            {...props}
            suffix={<SearchOutlined style={{color: '#D8D8D8', fontSize: 14}} />}
        ></Input>
    </div>;
};

export default MenuSearchContainer;
