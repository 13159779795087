import * as Sentry from '@sentry/react';
import {TransactionContext} from '@sentry/types';
export const sentrySevice = (config: TransactionContext) => {
    const {tags = {}} = config;
    const transaction = Sentry?.startTransaction({
        ...config,
        tags: {
            platform: 'helpage-cms',
            ...tags,
        },
    });
    Sentry?.getCurrentHub()?.configureScope(scope => scope.setSpan(transaction));
    const getCurrentHub = () => Sentry?.getCurrentHub();
    const finish = () => {
        transaction?.finish();
    };
    return {
        finish,
        getCurrentHub,
    };
};
