/*
 * @Author: shiruiqiang
 * @Date: 2023-08-15 16:37:56
 * @LastEditTime: 2023-12-19 14:22:57
 * @LastEditors: shiruiqiang
 * @FilePath: index.tsx
 * @Description: shiruiqiang
 */
import React from 'react';
import {Row, Col} from 'antd';
import './index.scss';

export const Grid = <T extends Record<string, any>>({
    column,
    data,
    row = 3,
}: {
    column: Array<{
        title: string;
        key?: string | string[];
        render?: (item: any, data: T) => React.ReactNode | string;
        colSpan?: number;
        hide?: boolean | ((item: any, data: T) => boolean);
    }>;
    data: T;
    row?: number;
}) => {
    return (
        <div className="grid-com">
            <Row>
                {column.map((col, index) => {
                    const {title, key, render, colSpan = 1, hide = false} = col;
                    const value = key
                        ? data
                            ? typeof key === 'string'
                                ? data?.[key]
                                : key.reduce((pre, cur) => pre?.[cur], data)
                            : ''
                        : '';
                    return (
                        (typeof hide === 'boolean' ? !hide : !(hide(value, data))) && (
                            <Col span={~~((24 / row) * colSpan)} key={index}>
                                <span className="label">{title}：</span>
                                <span>{render ? render(value, data) : value}</span>
                            </Col>
                        )
                    );
                })}
            </Row>
        </div>
    );
};
