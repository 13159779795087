import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useHeaderTabs} from 'app/models/tabs';

const ConditionalRedirect = ({from, to}: {from: string, to: string}) => {
    const {redirectPath} = useHeaderTabs();

    return (
        <Route
            path={from}
            render={() => {
                return <Redirect to={redirectPath || to} />;
            }}
        />
    );
};

export default ConditionalRedirect;
