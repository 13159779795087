import {Redirect, Route, RouteProps, Switch} from 'react-router-dom';
import React, {lazy, PropsWithChildren} from 'react';
import {Result, Image} from 'antd';
import ErrorBoundary from 'app/components/error';
import {Modules} from 'app/constants/moduleIds';
import {PermissionGuard} from 'app/components/guard';
import {RedirectComponent} from 'app/components/redirect';
import ConditionalRedirect from 'app/components/conditionalRedirect';
import {NoPermission} from 'app/hoc/WithAuth';
import NotFound from 'app/assets/notfound.png';
import {CommodityTypeEnum} from 'app/constants';
const Users = lazy(() => import('app/pages/user'));
const Orgs = lazy(() => import('app/pages/org'));
const Supplier = lazy(() => import('app/pages/supplierManage'));
const Elder = lazy(() => import('app/pages/elder'));
const Community = lazy(() => import('app/pages/community'));
const Activity = lazy(() => import('app/pages/activity/index.module'));
const Notice = lazy(() => import('app/pages/notice/notice.module'));
const CallRecords = lazy(() => import('app/pages/callRecords'));
const BusinessData = lazy(() => import('app/pages/businessdata'));
const Hospital = lazy(() => import('app/pages/hospital'));
const Banner = lazy(() => import('app/pages/banner'));
const HomeSkill = lazy(() => import('app/pages/homeSkill'));
const Iot = lazy(() => import('app/pages/iot'));
const Signature = lazy(() => import('app/pages/signature'));
const NewsType = lazy(() => import('app/pages/news/news.type'));
const News = lazy(() => import('app/pages/news'));
const Launch = lazy(() => import('app/pages/launcher'));
const VideoClass = lazy(() => import('app/pages/videoClass'));
const VideoClassType = lazy(() => import('app/pages/videoClass/videoClass.type'));
const Device = lazy(() => import('app/pages/device'));
const PhoneBook = lazy(() => import('app/pages/phonebook'));
const NewPhoneBook = lazy(() => import('app/pages/newphonebook'));
const SignIn = lazy(() => import('app/pages/signin'));
const BusinessLauncher = lazy(() => import('app/pages/businessLauncher'));
const Live = lazy(() => import('app/pages/liveBroadcast'));
const DailyUsers = lazy(() => import('app/pages/statisticsOverview/index'));
const SupplierConsumeStandindBook = lazy(() => import('app/pages/standingBook/supplierConsume'));
const BrokerageConsumeStandindBook = lazy(() => import('app/pages/standingBook/brokerageConsume'));
const DistributionConsumeStandindBook = lazy(() => import('app/pages/standingBook/distributionConsume'));
const CloseAccountStandingBook = lazy(() => import('app/pages/standingBook/closeAccount'));
const Service = lazy(() => import('app/pages/service'));
const Account = lazy(() => import('app/pages/account'));
const ChatBot = lazy(() => import('app/pages/chatBot'));
const FamilyDoctor = lazy(() => import('app/pages/familyDoctor'));
const Intent = lazy(() => import('app/pages/intentRecognition'));
export const RouteWrapper = (props: {component: React.ComponentType<any>, keys?: number[]} & Omit<RouteProps, 'component'>) => {
    const {component: Comp, keys = []} = props;
    return <Route {...props} component={(p: any) => (
        <PermissionGuard keys={keys} fallback={NoPermission}>
            <ErrorBoundary>
                <Comp {...p}></Comp>
            </ErrorBoundary>
        </PermissionGuard>
    )}></Route>;
};
export default ({children}: PropsWithChildren<{}>) => {
    return <Switch>
        <RouteWrapper keys={[Modules.StaffManage.id]} path="/users" component={Users}></RouteWrapper>
        <RouteWrapper keys={[Modules.OrganizationManage.id]} path="/orgs" component={Orgs}></RouteWrapper>
        <RouteWrapper keys={[Modules.SupplierManage.id]} path="/supplier" component={Supplier}></RouteWrapper>
        <RouteWrapper keys={[Modules.UserManage.id, Modules.FamilyDoctorManage.id]} path="/elder" component={Elder}></RouteWrapper>
        <RouteWrapper keys={[Modules.VillageManage.id]} path="/community" component={Community}></RouteWrapper>
        <RouteWrapper keys={[Modules.ActivityManage.id]} path="/activity" component={Activity}></RouteWrapper>
        <RouteWrapper keys={[Modules.NoticeManage.id]} path="/notice" component={Notice}></RouteWrapper>
        <RouteWrapper keys={[Modules.CustomerCenter.id, Modules.NewCustomerCenter.id]} path="/callrecords"
            component={CallRecords}
        ></RouteWrapper>
        <RouteWrapper keys={[Modules.BusinessData.id]} path="/businessdata" component={BusinessData}></RouteWrapper>
        <RouteWrapper keys={[Modules.HospitalManage.id]} path="/clinic" component={Hospital}></RouteWrapper>
        <RouteWrapper keys={[Modules.BannerConfig.id]} path="/banner" component={Banner}></RouteWrapper>
        <RouteWrapper keys={[Modules.HomeSkill.id]} path="/homeskill" component={HomeSkill}></RouteWrapper>
        <RouteWrapper keys={[Modules.IotDevice.id]} path="/iot" component={Iot}></RouteWrapper>
        <RouteWrapper keys={[Modules.Signature.id]} path="/signature" component={Signature}></RouteWrapper>
        <RouteWrapper keys={[Modules.NewsTypeManage.id]} path="/newstype" component={NewsType}></RouteWrapper>
        <RouteWrapper keys={[Modules.NewsManage.id]} path="/news" component={News}></RouteWrapper>
        <RouteWrapper keys={[Modules.LauncherManage.id]} path="/launch" component={Launch}></RouteWrapper>
        <RouteWrapper keys={[Modules.VideoClassManage.id]} path="/videoclass" component={VideoClass}></RouteWrapper>
        <RouteWrapper keys={[Modules.VideoClassTypeManage.id]} path="/videoclasstype"
            component={VideoClassType}
        ></RouteWrapper>
        <RouteWrapper keys={[Modules.DeviceManage.id]} path="/device" component={Device}></RouteWrapper>
        <RouteWrapper keys={[Modules.CustomerCenter.id]} path="/phonebook" component={PhoneBook}></RouteWrapper>
        <RouteWrapper keys={[Modules.NewCustomerCenter.id]} path="/newphonebook" component={NewPhoneBook}></RouteWrapper>
        <RouteWrapper keys={[Modules.SignInManage.id]} path="/signin" component={SignIn}></RouteWrapper>
        <RouteWrapper keys={[Modules.LiveManage.id]} path="/live" component={Live}></RouteWrapper>
        <RouteWrapper keys={[Modules.DataStatistics.id]} path="/overview" component={DailyUsers}></RouteWrapper>
        <RouteWrapper keys={[Modules.FamilyDoctorManage.id]} path="/familydoctor" component={FamilyDoctor}></RouteWrapper>
        <RouteWrapper keys={[Modules.ModuleRSAOpenPlatformManager.id]} path="/intent" component={Intent}></RouteWrapper>
        {/* 服务管理 */}
        <RouteWrapper keys={[Modules.ServiceManage.id, Modules.ServiceOrder.id]} path="/service"
            component={() => <Service categoryType={CommodityTypeEnum.service}></Service>}
        ></RouteWrapper>
        {/* 餐品管理 */}
        <RouteWrapper keys={[Modules.FoodsManage.id, Modules.FoodsOrder.id]} path="/food"
            component={() => <Service categoryType={CommodityTypeEnum.food}></Service>}
        ></RouteWrapper>
        {/* 商品管理 */}
        <RouteWrapper keys={[Modules.GoodsManage.id, Modules.GoodsOrder.id]} path="/goods"
            component={() => <Service categoryType={CommodityTypeEnum.commodity}></Service>}
        ></RouteWrapper>
        {/* 外卖管理 */}
        <RouteWrapper keys={[Modules.GoodsManage.id, Modules.GoodsOrder.id]} path="/takeaway"
            component={() => <Service categoryType={CommodityTypeEnum.takeaway}></Service>}
        ></RouteWrapper>
        {/* 行业桌面管理 */}
        <RouteWrapper keys={[Modules.BusinessLauncherManage.id]} path="/businesslauncher" component={BusinessLauncher}></RouteWrapper>
        {/* 社区业态 */}
        {/*  供应商消费台账 */}
        <RouteWrapper keys={[Modules.ConsumeStandindBook.id]} path="/consume/standingbook/supplier" component={SupplierConsumeStandindBook} exact></RouteWrapper>
        {/* 抽成消费台账 */}
        <RouteWrapper keys={[Modules.ConsumeStandindBook.id]} path="/consume/standingbook/brokerage" component={BrokerageConsumeStandindBook}></RouteWrapper>
        {/* 分成消费台账 */}
        <RouteWrapper keys={[Modules.ConsumeStandindBook.id]} path="/consume/standingbook/distribution" component={DistributionConsumeStandindBook}></RouteWrapper>
        {/* 结算台账 */}
        <RouteWrapper keys={[Modules.CloseAccountStandindBook.id]} path="/closeaccount/standingbook" component={CloseAccountStandingBook}></RouteWrapper>
        <Route path="/account" component={Account}></Route>
        <Route path="/chatBot" component={ChatBot}></Route>
        {children}
        <ConditionalRedirect from="/" to="/users" />
        <Route path="*">
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Result
                    icon={<Image src={NotFound} preview={false}></Image>}
                    title="Page Not Found!"
                ></Result>
            </div>
        </Route>
    </Switch>;
};
