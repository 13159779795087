import React, {useState, useEffect, useMemo} from 'react';
import {TabsProps} from 'antd';
import {HeaderTabsEnum, DynamicTabMenuMap, PlatformTypeEnum} from 'app/constants';
import {Modules} from 'app/constants/moduleIds';
import {useLocation, useHistory} from 'react-router-dom';
import {CmsDynamicRouteMenuMap, MenuRouteItem, MenuItem} from 'app/modules/cms/router-map';
import {GovDynamicRouteMenuMap} from 'app/modules/gov/router-map';
import {PlatformStore, useRootModel} from '..';

const defaultReddirectPath = '/users';
const TabsOption: TabsProps['items'] = [
    {key: HeaderTabsEnum.ResidentServices, label: '居民服务'},
    {key: HeaderTabsEnum.OrgManagement, label: '组织管理'},
    {key: HeaderTabsEnum.Statistics, label: '数据统计'},
    {key: HeaderTabsEnum.EquipmentOperation, label: '设备运营'},
    {key: HeaderTabsEnum.OrgBusiness, label: '社区业态'},
];

function getTabkeyWithModuleIds(ids: number[]) {
    let targetActiveKey: string = '';
    for (const tabKey of Object.keys(DynamicTabMenuMap)) {
        if (DynamicTabMenuMap[tabKey].some(_ => ids?.includes(_))) {
            targetActiveKey = tabKey;
            break;
        }
    }
    return targetActiveKey;
}

export const useHeaderTabs = () => {
    let redirectPath = '';
    let isPermission = true;
    const {userInfo, orgType} = useRootModel();
    const location = useLocation();
    const history = useHistory();
    const {modules = []} = userInfo || {};
    const {platform} = PlatformStore.usePlatform();
    const DynamicRouteMenuMap = platform === PlatformTypeEnum.CMS ? CmsDynamicRouteMenuMap : GovDynamicRouteMenuMap;
    const [currentTabsRoutes, setCurrentTabsRoutes] = useState<Array<Record<string, any>>>([]);
    const [tabOpenKeys, setTabOpenKeys] = useState<string[]>([]);
    const [activeTabKey, setActiveTabKey] = useState(() => {
        let targetPermissionKeys: undefined | number[] = [];
        let targetActiveKey: string = HeaderTabsEnum.OrgManagement;

        for (const menu of DynamicRouteMenuMap) {
            if (menu?.linkTo && location.pathname.includes(menu?.linkTo)) {
                targetPermissionKeys = menu.permissionKeys;
                break;
            } else if (menu.menuItem) {
                const item = menu.menuItem.find(item => location.pathname.includes(item.linkTo));
                if (item) {
                    targetPermissionKeys = item.permissionKeys || menu.permissionKeys;
                    break;
                }
            }
        }
        const moduleIds = modules.map(_ => _.id);
        isPermission = moduleIds.some(id => targetPermissionKeys?.includes(id)) || !targetPermissionKeys?.length;

        if (targetPermissionKeys.length && isPermission) {
            // 当前具有location.pathname并且匹配到菜单, 返回对应的tab
            targetActiveKey = getTabkeyWithModuleIds(targetPermissionKeys) || targetActiveKey;
        } else if (moduleIds?.includes(Modules.StaffManage.id)) {
            // 当前没有匹配到菜单, 但是有权限访问人员管理模块, 返回人员管理tab
            targetActiveKey = getTabkeyWithModuleIds([Modules.StaffManage.id]);
            redirectPath = defaultReddirectPath;
        } else {
            // 当前没有匹配到菜单, 也没有人员管理权限，返回第一个有权限的tab
            const tabOptions = TabsOption.filter(tabItem => getCurrentTabsRoutes(tabItem.key).length);
            if (tabOptions.length) {
                targetActiveKey = tabOptions[0].key;
            }
            // 获取第一个tab下，第一个菜单路由，作为重定向路由
            const initialTabRoutes = getCurrentTabsRoutes(targetActiveKey);
            const [firstMenu] = initialTabRoutes;
            if (firstMenu) {
                const path = firstMenu?.menuItem?.length
                    ? firstMenu?.menuItem?.[0].linkTo
                    : firstMenu?.linkTo;
                redirectPath = path || '';
            }
        }

        return targetActiveKey || HeaderTabsEnum.OrgManagement;
    });

    function hasPermission(id: number) {
        return modules.find(o => o.id === id);
    }

    function getCurrentTabsRoutes(key: string) {
        return DynamicRouteMenuMap.filter(routeItem => {
            const hasPermission = (id: number) => DynamicTabMenuMap[key].includes(id);
            const filterPermissionKeys = (item: MenuRouteItem | MenuItem) => {
                const {permissionKeys = [], collateralPermissionKeys = []} = item || {};
                return permissionKeys.filter((key: number) => !collateralPermissionKeys.includes(key));
            };
            const checkPermissions = (item: MenuRouteItem | MenuItem) => filterPermissionKeys(item).some(hasPermission);
            const hasRoutePermission = checkPermissions(routeItem);
            const hasMenuItemPermission = (routeItem?.menuItem || []).some(checkPermissions);

            return hasRoutePermission || hasMenuItemPermission;
        }).filter(routeItem => {
            return routeItem.permissionKeys.some(hasPermission)
                && (!routeItem.isDisplay || !!routeItem.isDisplay(() => orgType === 1));
        }).map(routeItem => {
            if (routeItem.menuItem && !!routeItem.menuItem.length) {
                const newMenuItem = routeItem.menuItem.filter(_ => {
                    const hasPermissionKeys = !!_?.permissionKeys?.length;
                    return (!_.isDisplay || !!_.isDisplay(() => orgType === 1))
                        && (!hasPermissionKeys || _?.permissionKeys?.some(hasPermission));
                });
                return {
                    ...routeItem,
                    menuItem: newMenuItem,
                };
            } else {
                return routeItem;
            }
        });
    }

    const changeActiveTabKey = (key: string) => {
        const menuList = getCurrentTabsRoutes(key);
        // setCurrentTabsRoutes(menuList);
        const [firstMenu] = menuList;
        if (firstMenu) {
            const linkTo = firstMenu?.menuItem?.length
                ? firstMenu?.menuItem?.[0].linkTo
                : firstMenu?.linkTo;
            linkTo && history.push(linkTo);
        }
        setActiveTabKey(key);
    };

    const TabOptions = useMemo(() => {
        return TabsOption.filter(tabItem => getCurrentTabsRoutes(tabItem.key).length);
    }, []);

    useEffect(() => {
        let pathname = location?.pathname;
        if (redirectPath && (!pathname || pathname === '/' || !isPermission)) {
            pathname = redirectPath;
            if (!isPermission) {
                history.push(pathname);
            }
        }
        const targetMenu = DynamicRouteMenuMap.find(menu =>
            menu.menuItem?.some(item => item.linkTo === pathname)
        );
        if (targetMenu) {
            setTabOpenKeys([targetMenu.eventKey]);
        }
    }, [activeTabKey]);
    useEffect(() => {
        setCurrentTabsRoutes(getCurrentTabsRoutes(activeTabKey));
    }, [activeTabKey]);

    return {
        pathname: redirectPath || location?.pathname,
        activeTabKey,
        currentTabsRoutes,
        tabOpenKeys,
        setTabOpenKeys,
        changeActiveTabKey,
        TabOptions,
        setActiveTabKey,
        redirectPath,
    };
};
