import React from 'react';
import {Badge} from 'antd';
import {Modules} from 'app/constants/moduleIds';
import {CommodityKeyEnum} from 'app/constants';
import {
    ActivityIcon,
    CallRecordsIcon,
    CommodityIcon,
    DesktopIcon,
    DeviceIcon,
    ElderIcon,
    FoodIcon,
    Hospital,
    IotIcon,
    NewsIcon,
    Notice,
    ServiceManageIcon,
    Slider,
    StaffManageIcon,
    SubOrgIcon,
    OperateIcon,
    AppDataIcon,
    QueryDataIcon,
} from 'app/assets/icon';
import Icon, {PieChartOutlined} from '@ant-design/icons';
import {useOrderModel} from 'app/models';
import {NavLink} from 'react-router-dom';
import {ISOLATED_PAGE, ISOLATED_PAGE_ONLINE_CONSULTATION} from 'app/models/layout';

export interface MenuItem {
    name?: string;
    eventKey: string;
    linkTo: string;
    permissionKeys?: number[];
    collateralPermissionKeys?: number[];
    nestedComponent?: () => React.ReactNode;
    isDisplay?: (fn: () => boolean) => boolean;
}
export interface MenuRouteItem {
    name: string;
    eventKey: string;
    permissionKeys: number[];
    collateralPermissionKeys?: number[]; // 当用户拥有相应权限时，可以访问该模块的页面，但该模块不会在菜单中显示。
    icon: React.ReactNode;
    linkTo?: string;
    nestedComponent?: () => React.ReactNode;
    menuItem?: MenuItem[];
    isDisplay?: (fn: () => boolean) => boolean;
}

export const CmsDynamicRouteMenuMap: MenuRouteItem[] = [
    {
        name: '餐品管理',
        eventKey: `/${CommodityKeyEnum.FOOD}`,
        permissionKeys: [Modules.FoodsManage.id, Modules.FoodsOrder.id],
        icon: <Icon component={FoodIcon} />,
        menuItem: [
            {
                name: '餐品类型',
                eventKey: `/${CommodityKeyEnum.FOOD}/category`,
                linkTo: `/${CommodityKeyEnum.FOOD}/category`,
                isDisplay: (fn: () => boolean) => fn(),
            },
            {
                name: '餐品库管理',
                eventKey: `/${CommodityKeyEnum.FOOD}`,
                linkTo: `/${CommodityKeyEnum.FOOD}`,
                permissionKeys: [Modules.FoodsManage.id],
            },
            {
                name: '餐品售卖管理',
                eventKey: `/${CommodityKeyEnum.FOOD}/menu`,
                linkTo: `/${CommodityKeyEnum.FOOD}/menu`,
            },
            {
                name: '餐品订单',
                eventKey: `/${CommodityKeyEnum.FOOD}/order`,
                linkTo: `/${CommodityKeyEnum.FOOD}/order`,
                permissionKeys: [Modules.FoodsOrder.id],
                nestedComponent: () => {
                    const orderinfo = useOrderModel();
                    return <NavLink to={`/${CommodityKeyEnum.FOOD}/order`}>
                        <Badge dot={!!orderinfo.food} size="small" offset={[5, 10]} overflowCount={99}>
                    餐品订单
                        </Badge>
                    </NavLink>;
                },
            },
        ],
    },
    {
        name: '商品管理',
        eventKey: `/${CommodityKeyEnum.GOODS}`,
        permissionKeys: [Modules.GoodsManage.id, Modules.GoodsOrder.id],
        icon: <Icon component={CommodityIcon} />,
        menuItem: [
            {
                name: '商品类型',
                eventKey: `/${CommodityKeyEnum.GOODS}/category`,
                linkTo: `/${CommodityKeyEnum.GOODS}/category`,
                isDisplay: (fn: () => boolean) => fn(),
            },
            {
                name: '商品管理',
                eventKey: `/${CommodityKeyEnum.GOODS}`,
                linkTo: `/${CommodityKeyEnum.GOODS}`,
                permissionKeys: [Modules.GoodsManage.id],
            },
            {
                name: '商品订单',
                eventKey: `/${CommodityKeyEnum.GOODS}/order`,
                linkTo: `/${CommodityKeyEnum.GOODS}/order`,
                permissionKeys: [Modules.GoodsOrder.id],
                nestedComponent: () => {
                    const orderinfo = useOrderModel();
                    return <NavLink to={`/${CommodityKeyEnum.GOODS}/order`}>
                        <Badge dot={!!orderinfo.goods} size="small" offset={[5, 10]} overflowCount={99}>
                    商品订单
                        </Badge>
                    </NavLink>;
                },
            },
        ],
    },
    {
        name: '服务管理',
        eventKey: `${CommodityKeyEnum.SERVICE}`,
        permissionKeys: [Modules.ServiceManage.id, Modules.ServiceOrder.id],
        icon: <Icon component={ServiceManageIcon} />,
        menuItem: [
            {
                name: '服务类型',
                eventKey: `/${CommodityKeyEnum.SERVICE}/category`,
                linkTo: `/${CommodityKeyEnum.SERVICE}/category`,
                isDisplay: (fn: () => boolean) => fn(),
            },
            {
                name: '服务管理',
                eventKey: `/${CommodityKeyEnum.SERVICE}`,
                linkTo: `/${CommodityKeyEnum.SERVICE}`,
                permissionKeys: [Modules.ServiceManage.id],
            },
            {
                name: '服务订单',
                eventKey: `/${CommodityKeyEnum.SERVICE}/order`,
                linkTo: `/${CommodityKeyEnum.SERVICE}/order`,
                permissionKeys: [Modules.ServiceOrder.id],
                nestedComponent: () => {
                    const orderinfo = useOrderModel();
                    return <NavLink to={`/${CommodityKeyEnum.SERVICE}/order`}>
                        <Badge dot={!!orderinfo.service} size="small" offset={[5, 10]} overflowCount={99}>
                    服务订单
                        </Badge>
                    </NavLink>;
                },
            },
        ],
    },
    {
        name: '外卖管理',
        eventKey: `/${CommodityKeyEnum.TAKEAWAY}`,
        permissionKeys: [Modules.TakeAwayManage.id, Modules.TakeAwayOrder.id],
        icon: <Icon component={CommodityIcon} />,
        menuItem: [
            {
                name: '外卖类型',
                eventKey: `/${CommodityKeyEnum.TAKEAWAY}/category`,
                linkTo: `/${CommodityKeyEnum.TAKEAWAY}/category`,
                isDisplay: (fn: () => boolean) => fn(),
            },
            {
                name: '外卖管理',
                eventKey: `/${CommodityKeyEnum.TAKEAWAY}`,
                linkTo: `/${CommodityKeyEnum.TAKEAWAY}`,
                permissionKeys: [Modules.TakeAwayManage.id],
            },
            {
                name: '外卖订单',
                eventKey: `/${CommodityKeyEnum.TAKEAWAY}/order`,
                linkTo: `/${CommodityKeyEnum.TAKEAWAY}/order`,
                permissionKeys: [Modules.TakeAwayOrder.id],
                nestedComponent: () => {
                    const orderinfo = useOrderModel();
                    return <NavLink to={`/${CommodityKeyEnum.TAKEAWAY}/order`}>
                        <Badge dot={!!orderinfo.takeAway} size="small" offset={[5, 10]} overflowCount={99}>
                            外卖订单
                        </Badge>
                    </NavLink>;
                },
            },
        ],
    },
    {
        name: '医院管理',
        eventKey: '/clinic',
        permissionKeys: [Modules.HospitalManage.id],
        icon: <Icon component={Hospital} />,
        linkTo: '/clinic',
    },
    {
        name: '活动管理',
        eventKey: 'activity',
        permissionKeys: [Modules.ActivityManage.id, Modules.SignInManage.id],
        icon: <Icon component={ActivityIcon} />,
        menuItem: [
            {
                name: '社区活动管理',
                eventKey: '/activity',
                linkTo: '/activity',
                permissionKeys: [Modules.ActivityManage.id],
            },
            {
                name: '签到活动管理',
                eventKey: '/signin',
                linkTo: '/signin',
                permissionKeys: [Modules.SignInManage.id],
            },
        ],
    },
    {
        name: '通知管理',
        eventKey: '/notice',
        permissionKeys: [Modules.NoticeManage.id, Modules.Signature.id],
        icon: <Icon component={Notice} />,
        menuItem: [
            {
                name: '通知管理',
                eventKey: '/notice',
                linkTo: '/notice',
                permissionKeys: [Modules.NoticeManage.id],
            },
            {
                name: '签名管理',
                eventKey: '/signature',
                linkTo: '/signature',
                permissionKeys: [Modules.Signature.id],
            },
        ],
    },
    {
        name: '通话管理',
        eventKey: '/newphonebook',
        permissionKeys: [Modules.NewCustomerCenter.id, Modules.CustomerCenter.id],
        icon: <Icon component={CallRecordsIcon} />,
        menuItem: [
            {
                name: '通讯录管理',
                eventKey: '/phonebook',
                linkTo: '/phonebook',
                permissionKeys: [Modules.CustomerCenter.id],
            },
            {
                name: '通讯录管理',
                eventKey: '/newphonebook',
                linkTo: '/newphonebook',
                permissionKeys: [Modules.NewCustomerCenter.id],
            },
            {
                name: '通话记录',
                eventKey: '/callrecords',
                linkTo: '/callrecords',
                permissionKeys: [Modules.NewCustomerCenter.id, Modules.CustomerCenter.id],
            },
        ],
    },
    {
        name: '社区要闻',
        eventKey: 'news',
        permissionKeys: Modules.AllNewsModules.ids,
        icon: <Icon component={NewsIcon} />,
        menuItem: [
            {
                name: '要闻管理',
                eventKey: '/news',
                linkTo: '/news',
                permissionKeys: [Modules.NewsManage.id],
            },
            {
                name: '要闻类型',
                eventKey: '/newstype',
                linkTo: '/newstype',
                permissionKeys: [Modules.NewsTypeManage.id],
            },
        ],
    },
    {
        name: '社区视频',
        eventKey: 'videoclass',
        permissionKeys: Modules.AllVideoClassModules.ids,
        icon: <Icon component={NewsIcon} />,
        menuItem: [
            {
                name: '社区视频管理',
                eventKey: '/videoclass',
                linkTo: '/videoclass',
                permissionKeys: [Modules.VideoClassManage.id],
            },
            {
                name: '视频类型',
                eventKey: '/videoclasstype',
                linkTo: '/videoclasstype',
                permissionKeys: [Modules.VideoClassTypeManage.id],
            },
        ],
    },
    {
        name: '直播管理',
        eventKey: '/live',
        permissionKeys: [Modules.LiveManage.id],
        icon: <Icon component={NewsIcon} />,
        linkTo: '/live',
    },
    {
        name: '员工管理',
        eventKey: '/users',
        permissionKeys: [Modules.StaffManage.id],
        icon: <Icon component={StaffManageIcon} />,
        linkTo: '/users',
    },
    {
        name: '机构管理',
        eventKey: '/orgs',
        permissionKeys: [Modules.OrganizationManage.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/orgs',
    },
    {
        name: '供应商管理',
        eventKey: '/supplier',
        permissionKeys: [Modules.SupplierManage.id, Modules.BillingProfile.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/supplier',
    },
    {
        name: '居民管理',
        eventKey: 'elder',
        permissionKeys: [Modules.UserManage.id],
        collateralPermissionKeys: [Modules.FamilyDoctorManage.id],
        icon: <Icon component={ElderIcon} />,
        menuItem: [
            {
                name: '居民管理',
                eventKey: '/elder/list',
                linkTo: '/elder/list',
                permissionKeys: [Modules.UserManage.id],
                collateralPermissionKeys: [Modules.FamilyDoctorManage.id],
            },
            {
                name: '居民标签管理',
                eventKey: '/elder/types',
                linkTo: '/elder/types',
                permissionKeys: [Modules.UserTypeManage.id],
            },
        ],
    },
    // {
    //     name: '商业数据',
    //     eventKey: '/businessdata',
    //     permissionKeys: [Modules.BusinessData.id],
    //     icon: <AreaChartOutlined />,
    //     linkTo: '/businessdata',
    // },
    {
        name: '数据总览',
        eventKey: '/overview/dataOverView',
        permissionKeys: [Modules.DataStatistics.id],
        icon: <PieChartOutlined />,
        linkTo: '/overview/dataOverView',
    },
    {
        name: '经营数据',
        eventKey: '/overview/businessDataView',
        permissionKeys: [Modules.DataStatistics.id],
        icon: <Icon component={OperateIcon} />,
        linkTo: '/overview/businessDataView',
    },
    {
        name: 'query数据',
        eventKey: '/overview/queryDataView',
        permissionKeys: [Modules.DataStatistics.id],
        icon: <Icon component={QueryDataIcon} />,
        linkTo: '/overview/queryDataView',
    },
    {
        name: '应用数据',
        eventKey: '/overview/dataApplication',
        permissionKeys: [Modules.DataStatistics.id],
        icon: <Icon component={AppDataIcon} />,
        linkTo: '/overview/dataApplication',
    },
    {
        name: '数据看板',
        eventKey: '/dataall',
        permissionKeys: [Modules.DataStatistics.id],
        icon: <PieChartOutlined />,
        linkTo: '/dataall',
        nestedComponent: () => {
            return <a href="/dataall" target="_blank" rel="noopener noreferrer">
            数据看板
            </a>;
        },
    },
    {
        name: 'RSA开放广场',
        eventKey: '/intent',
        permissionKeys: [Modules.ModuleRSAOpenPlatformManager.id],
        icon: <Icon component={OperateIcon} />,
        isDisplay: (fn: () => boolean) => fn(),
        menuItem: [
            {
                name: '意图识别开放能力',
                eventKey: '/intent',
                linkTo: '/intent',
            },
            {
                name: '私域知识库问答',
                eventKey: '/intent/repository',
                linkTo: '/intent/repository',
            },
        ],
    },
    {
        name: '音箱桌面管理',
        eventKey: '/businesslauncher',
        permissionKeys: [Modules.BusinessLauncherManage.id],
        icon: <Icon component={DesktopIcon} />,
        linkTo: '/businesslauncher',
    },
    {
        name: '桌面管理',
        eventKey: '/launch',
        permissionKeys: [Modules.LauncherManage.id],
        icon: <Icon component={DesktopIcon} />,
        linkTo: '/launch',
    },
    {
        name: '轮播图管理',
        eventKey: '/banner',
        permissionKeys: [Modules.BannerConfig.id],
        icon: <Icon component={Slider} />,
        linkTo: '/banner',
    },
    {
        name: '居家设备管理',
        eventKey: 'iot',
        permissionKeys: [Modules.IotDevice.id],
        icon: <Icon component={IotIcon} />,
        menuItem: [
            {
                name: '设备列表',
                eventKey: '/iot',
                linkTo: '/iot',
            },
            {
                name: '报警记录',
                eventKey: '/iot/device/alert',
                linkTo: '/iot/device/alert',
            },
        ],
    },
    {
        name: '智能音箱管理',
        eventKey: '/device',
        permissionKeys: [Modules.DeviceManage.id],
        icon: <Icon component={DeviceIcon} />,
        linkTo: '/device',
    },
    // {
    //     name: 'HOME技能管理',
    //     eventKey: '/homeskill',
    //     permissionKeys: [Modules.HomeSkill.id],
    //     icon: <Icon component={HomeSkill} />,
    //     linkTo: '/homeskill',
    // },
    {
        name: '台账总览',
        eventKey: '/supplier/billing',
        permissionKeys: [Modules.BillingProfile.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/supplier/billing',
    },
    {
        name: '消费台账',
        eventKey: '/consume/standingbook',
        permissionKeys: [Modules.ConsumeStandindBook.id, Modules.DistributionStandindBook.id, Modules.CommissionStandindBook.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/consume/standingbook',
        menuItem: [
            {
                name: '供应商台账',
                eventKey: '/consume/standingbook/supplier',
                permissionKeys: [Modules.ConsumeStandindBook.id],
                linkTo: '/consume/standingbook/supplier',
            },
            {
                name: '分成台账',
                eventKey: '/consume/standingbook/distribution',
                permissionKeys: [Modules.DistributionStandindBook.id],
                linkTo: '/consume/standingbook/distribution',
            },
            {
                name: '抽成台账',
                eventKey: '/consume/standingbook/brokerage',
                permissionKeys: [Modules.CommissionStandindBook.id],
                linkTo: '/consume/standingbook/brokerage',
            },
        ],
    },
    {
        name: '结算台账',
        eventKey: '/closeaccount/standingbook',
        permissionKeys: [Modules.CloseAccountStandindBook.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/closeaccount/standingbook',
    },
    {
        name: '我的账户',
        eventKey: '/account',
        permissionKeys: [Modules.AccountManage.id],
        icon: <Icon component={SubOrgIcon} />,
        linkTo: '/account',
    },
    {
        name: '家庭医生',
        eventKey: '/familydoctor',
        permissionKeys: [Modules.FamilyDoctorManage.id],
        icon: <Icon component={NewsIcon} />,
        menuItem: [
            {
                name: '医生管理',
                eventKey: '/familydoctor/doctormanage',
                linkTo: '/familydoctor/doctormanage',
                permissionKeys: [Modules.FamilyDoctorManage.id],
            },
            {
                name: '接诊记录',
                eventKey: '/familydoctor/receptionrecord',
                linkTo: '/familydoctor/receptionrecord',
                permissionKeys: [Modules.FamilyDoctorManage.id],
            },
            {
                name: '在线问诊',
                eventKey: '/familydoctor/onlineconsultation',
                linkTo: '/familydoctor/onlineconsultation',
                permissionKeys: [Modules.FamilyDoctorManage.id],
                nestedComponent: () => {
                    return <a
                        href={`/familydoctor/onlineconsultation?${ISOLATED_PAGE}=${ISOLATED_PAGE_ONLINE_CONSULTATION}`}
                        target="_blank"
                        rel="noopener
                    noreferrer"
                    >
                    在线问诊
                    </a>;
                },
            },
        ],
    },
];
