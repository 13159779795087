/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
* @file state.ts
* @author pankairan
* @date 2021-12-21 10:54
*/

import React, {createContext, PropsWithChildren, useContext} from 'react';

type Arguments<T> = T extends (...args: infer U) => unknown ? U : T;
type RecordHooksValue<T extends Record<string, HooksFactory>> = {[K in keyof T]: ReturnType<T[K]>};

type RecordHooksConsumer<T extends Record<string, HooksFactory>> = {[K in keyof T]: () => ReturnType<T[K]>};
type RecordHooksArgs<T extends Record<string, HooksFactory>> = {[K in keyof T]?: Arguments<T[K]>};
type HooksFactory = (...args: never[]) => unknown;

/**
 * 组件间共享hooks状态，无需进行手动的context包裹嵌套
 * @param models Record<string, HooksFactory>
 * @example
 ```
    const store = createStore({
        useCounter: (initValue) => {
            const [count, setCount] = useState(initValue)
            const actions = {
                increment: (v) => setCount(v => v + count)
            }
            return {count, actions}
        }
    })
    const Home = () => {
        const {count, actions} = store.useCounter();
        return <div>{count} <button onClick={() => actions.increment(1)}></button></div>
    }
    ReactDom.render(() =>
        <store.Provider initArgs={{
            counter: [1]
        }}>
            <Home/>
        </store.Provider>
    )
```
 * @returns {Provider, useModel}
 */
export const createStore = <T extends Record<string, HooksFactory>>(models: T) => {
    const Contexts = Object.keys(models).map((moduleName: keyof T) => {
        const CTX = createContext<ReturnType<T[keyof T]> | undefined>(undefined);
        return {[moduleName]: CTX};
    }).reduceRight((acc, next) => ({...acc, ...next}));
    const ProviderComposer = (props: PropsWithChildren<{initArgs?: RecordHooksArgs<T>}>) => {
        const {children, initArgs} = props;
        return Object.keys(models).reduceRight((children, key) => {
            const CTX = Contexts[key];
            const hookFactory = models[key];
            const args = initArgs ? (initArgs[key] || []) : [];
            const value = hookFactory(...args) as ReturnType<T[keyof T]>;
            return <CTX.Provider value={value}>{children}</CTX.Provider>;
        }, children) as JSX.Element;
    };

    const injectProvider
        = <P, >(
            Compont: React.ComponentType<P>,
            injectArgs?: RecordHooksArgs<T>,
            mapProps?: (props: P) => RecordHooksArgs<T>
        ): React.ComponentType<P> =>
                props => {
                    return (
                        <ProviderComposer initArgs={mapProps ? mapProps(props) : injectArgs}>
                            <Compont {...props}></Compont>
                        </ProviderComposer>
                    );
                };
    const consumer = Object.keys(models).reduceRight((acc, key) => {
        const CTX = Contexts[key];
        return {
            [key]: () => {
                return useContext(CTX);
            }, ...acc,
        };
    }, {}) as RecordHooksConsumer<T>;
    return {
        Provider: ProviderComposer,
        injectProvider,
        ...consumer,
    };
};
