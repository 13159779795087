/**
 * @ Author: laiwei01
 * @ Create Time: 2023-08-02 14:55:15
 * @ Modified by: laiwei01
 * @ Modified time: 2023-08-03 11:03:40
 * @ Description:
 */


import React from 'react';
import {Input, InputProps} from 'antd';
import {TextAreaProps} from 'antd/es/input/TextArea';
const {TextArea} = Input;

const showCountNode = {
    formatter: (args: {
        value: string; count: number; maxLength?: number;
    }) => {
        return `${args.count}/${args.maxLength}`;
    },
};

export const StandardInput = (props: InputProps) => {
    const {showCount, maxLength, ...rest} = props;
    return <Input
        showCount={showCount || !!maxLength && showCountNode}
        maxLength={maxLength}
        {...rest}
    />;
};

export const StandardTextArea = (props: TextAreaProps) => {
    const {showCount, maxLength, ...rest} = props;
    return <TextArea
        showCount={showCount || !!maxLength && showCountNode}
        maxLength={maxLength}
        className="ant-input-textarea"
        {...rest}
    />;
};
