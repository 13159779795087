
import {Modules} from 'app/constants/moduleIds';
export * from './commodity';
export enum GenderEnum {
    boy = 1,
    girl = 2,
}

// 性别
export const genderList = [
    {name: '男', val: GenderEnum.boy},
    {name: '女', val: GenderEnum.girl},
];

export enum SkillMap {
    food = 'food',
    service = 'familyService',
    shop = 'shop',
    health = 'health',
    activity = 'communityActivities',
    news = 'news',
    video = 'videoclass',
    elderInfo = 'elderInfo',
    signin = 'signin',
    live = 'live',
}

export enum NewSkillMap {
    food = 'elderFood',
    service = 'elderService',
    shop = 'elderShop',
    health = 'elderHealth',
    activity = 'communityActivity',
    news = 'elderNews',
    video = 'elderVideoClass',
    elderInfo = 'elderInfo',
    signin = 'signin',
    home = 'elderHome',
    phone = 'elderPhone',
}


export const OrgTypes = [
    {
        id: 1,
        name: 'company',
    },
    {
        id: 2,
        name: 'department',
    },
    {
        id: 3,
        name: 'station',
    },
];

export const OrgStatusList = [
    {text: '全部', value: 0},
    {text: '启用', value: 1},
    {text: '停用', value: 2},
];

export enum BloodEnum {
    A = 1,
    B = 2,
    O = 3,
    AB = 4,
    Other = 5,
}

export const BloodType = {
    [BloodEnum.A]: 'A',
    [BloodEnum.B]: 'B',
    [BloodEnum.O]: 'O',
    [BloodEnum.AB]: 'AB',
    [BloodEnum.Other]: '其他',
};

export const DeviceStatus = [
    {name: '在线', val: 1, color: 'green'},
    {name: '报警', val: 2, color: 'red'},
    {name: '离线', val: 3, color: 'orange'},
];

export const DefaultPn = 1;
export const DefaultRn = 10;

export const SkillLink: Record<string, {name: string, link: string, deepLink: string}> = {
    [SkillMap.news]: {
        name: '地方政策',
        link: '79b1def3-6c37-8e7e-ac49-77f74190ee11',
        deepLink: 'bdacspeldernews://home',
    },
    [SkillMap.video]: {
        name: '社区视频',
        link: '0e3d35b0-935e-83ec-5a61-dae8fa965196',
        deepLink: 'bdacspeldervideocls://home',
    },
    [SkillMap.food]: {
        name: '社区食堂',
        link: '17ac3f5d-ab40-4187-6f01-3fe3b29e60fe',
        deepLink: 'bdacspelderfood://home',
    },
    [SkillMap.service]: {
        name: '社区服务',
        link: 'eede41f8-2c65-0db5-e334-56e1d2f218bc',
        deepLink: 'bdacspelderservice://home',
    },
    [SkillMap.shop]: {
        name: '社区商城',
        link: '4624dad3-c832-faee-7cd2-9601a0e69c6f',
        deepLink: 'bdacspeldershop://home',
    },
    [SkillMap.activity]: {
        name: '社区活动',
        link: '1cb96483-0d40-ea42-e01d-70924cca5060',
        deepLink: 'bdacspcommunityactivity://home',
    },
    [SkillMap.elderInfo]: {
        name: '长者信息',
        link: '',
        deepLink: 'bdacspelderinfo://home',
    },
    // 签到需要后端补充该技能的返回
    [SkillMap.signin]: {
        name: '签到',
        link: '4f690b85-1cce-e7f3-834b-1c471afb87e1',
        deepLink: 'bdacspdailysignin://home',
    },
};

export const NewSkillLink: Record<string, {botId: string}> = {
    [NewSkillMap.news]: {
        botId: 'ca1aa976-b470-5295-dd43-09a7f7153104',
    },
    [NewSkillMap.video]: {
        'botId': '638c821e-9b0e-37b0-53cb-18ca206de483',
    },
    [NewSkillMap.food]: {
        'botId': '17ac3f5d-ab40-4187-6f01-3fe3b29e60fe',
    },
    [NewSkillMap.service]: {
        'botId': 'eede41f8-2c65-0db5-e334-56e1d2f218bc',
    },
    [NewSkillMap.shop]: {
        'botId': '4624dad3-c832-faee-7cd2-9601a0e69c6f',
    },
    [NewSkillMap.activity]: {
        'botId': '755504fb-cc58-863f-d187-f605739880b3',
    },
    [NewSkillMap.elderInfo]: {
        'botId': '625447bc-a687-54b6-9cf5-f6fa1371ec16',
    },
    // 签到需要后端补充该技能的返回
    [NewSkillMap.signin]: {
        'botId': '4f690b85-1cce-e7f3-834b-1c471afb87e1',
    },
    [NewSkillMap.home]: {
        'botId': '638c821e-9b0e-37b0-53cb-18ca206de483',
    },
    [NewSkillMap.phone]: {
        'botId': '24102f20-d9dc-b614-7d73-6378f69bd9b7',
    },
};

export enum PlatformTypeEnum{
    CMS = 1,
    GOV = 2
}
export const EmployeeType = [
    {
        label: 'cms',
        value: PlatformTypeEnum.CMS,
    },
    {
        label: 'gov',
        value: PlatformTypeEnum.GOV,
    },
];

export enum HeaderTabsEnum {
    ResidentServices = 'service',
    OrgManagement = 'org',
    Statistics = 'data',
    EquipmentOperation = 'device',
    // 社区业态
    OrgBusiness = 'business',
}

export const HeaderTabsRouteMap: Record<string, string> = {
    [HeaderTabsEnum.ResidentServices]: '/service/category',
    [HeaderTabsEnum.OrgManagement]: '/users',
    [HeaderTabsEnum.Statistics]: '/businessdata',
    [HeaderTabsEnum.EquipmentOperation]: '/homeskill',
    [HeaderTabsEnum.OrgBusiness]: '/supplier',
};

export const DynamicTabMenuMap: Record<string, number[]> = {
    [HeaderTabsEnum.ResidentServices]: [Modules.FoodsManage.id, Modules.GoodsManage.id, Modules.ServiceManage.id,
        Modules.MedicalManage.id, Modules.ActivityManage.id, Modules.NoticeManage.id, Modules.ServiceOrder.id,
        Modules.HospitalManage.id, Modules.SignInManage.id, Modules.Signature.id, ...Modules.AllNewsModules.ids,
        ...Modules.AllVideoClassModules.ids, Modules.GoodsOrder.id, Modules.FoodsOrder.id,
        Modules.NewCustomerCenter.id, Modules.CustomerCenter.id, Modules.LiveManage.id, Modules.TakeAwayManage.id,
        Modules.TakeAwayOrder.id, Modules.FamilyDoctorManage.id],
    [HeaderTabsEnum.OrgManagement]: [Modules.StaffManage.id, Modules.OrganizationManage.id,
        Modules.UserManage.id, Modules.UserTypeManage.id, Modules.GridmanManage.id, Modules.AccountManage.id],
    [HeaderTabsEnum.Statistics]: [Modules.BusinessData.id, Modules.DataStatistics.id],
    [HeaderTabsEnum.EquipmentOperation]: [Modules.ModuleRSAOpenPlatformManager.id, Modules.LauncherManage.id, Modules.BusinessLauncherManage.id, Modules.IotDevice.id,
        Modules.BannerConfig.id, Modules.HomeSkill.id, Modules.DeviceManage.id],
    [HeaderTabsEnum.OrgBusiness]: [Modules.SupplierManage.id, Modules.ConsumeStandindBook.id, Modules.BillingProfile.id,
        Modules.DistributionStandindBook.id, Modules.CommissionStandindBook.id],
};

// 供应商供应方式
export enum SupplyMethodEnum{
    STATION = 1, // 服务站
    SUPPLIER = 2 // 供应商
}

export enum OrgTypeEnum{
    SELF = 'self',
    JOIN = 'join'
}
export const OrgType = [
    {
        label: '自营',
        value: OrgTypeEnum.SELF,
    },
    {
        label: '加盟',
        value: OrgTypeEnum.JOIN,
    },
];

export enum JoinTypeEnum{
    DISTRIBUTION = 'distribution',
    BROKERAGE = 'brokerage',
}
export const JoinType = [
    {
        label: '分销',
        value: JoinTypeEnum.DISTRIBUTION,
    },
    {
        label: '抽成',
        value: JoinTypeEnum.BROKERAGE,
    },
];
export enum NoticeScopeType {
    // 全部
    all = 1,
    // 标签
    label = 2,
    // 自定义
    custom = 3,
    // 指定机构
    org = 4,
}
// 通知范围
export const NoticeScopes = [
    {
        name: '全部',
        val: NoticeScopeType.all,
    },
    {
        name: '标签用户',
        val: NoticeScopeType.label,
    },
    {
        name: '指定用户',
        val: NoticeScopeType.custom,
    },
    {
        name: '指定机构',
        val: NoticeScopeType.org,
    },
];
export * from './pay';

