/*
 * @Author: shiruiqiang
 * @Date: 2023-07-30 20:22:41
 * @LastEditTime: 2023-08-21 14:31:18
 * @LastEditors: shiruiqiang
 * @FilePath: index.ts
 * @Description: shiruiqiang
 */
/**
* @file index.ts
* @author pankairan
* @date 2022-06-23 14:26
*/
import {message} from 'antd';
import {postApi} from 'app/utils/fetch';
import {OrgSkillListRequest, OrgSkillListResponse, OrgSkillItem} from 'proto/organization';
import {useEffect, useState} from 'react';
import {useRootModel} from '..';
import {SkillMap} from 'app/constants';

export enum SkillTypeEnum {
    APK = 'apk',
    DPL ='dpl',
}

const fetchSkills = async (payload: OrgSkillListRequest) => {
    return postApi<OrgSkillListResponse>('/organization/skill-list', payload).then(res => {
        return res.list.map(item => ({
            ...item,
            // skillUrl 仅仅是 bot 前缀,例如 dueros://${BOTID},后面拼什么还需要实际处理
            skillUrl: `dueros://${item.skillId}`,
        }));
    }).catch(err => {
        message.error(err.message);
        return [];
    });
};

export type OrgSkill = OrgSkillItem & {skillUrl: string};
export const useSkills = () => {
    const {orgId} = useRootModel();
    const [skills, setSkills] = useState <OrgSkill[]>([]);
    useEffect(() => {
        if (orgId > 0) {
            fetchSkills({orgId}).then(data => setSkills(data));
        }
    }, [orgId]);
    const actions = {
        getSkillId: (skill: SkillMap) => {
            return skills.find(item => item.skillKey === skill)?.skillId; // 新闻技能链接
        },
    };
    return {skills, actions};
};
