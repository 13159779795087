/*
 * @Author: shiruiqiang
 * @Date: 2023-08-15 16:37:56
 * @LastEditTime: 2023-12-12 14:33:52
 * @LastEditors: shiruiqiang
 * @FilePath: moduleIds.ts
 * @Description: shiruiqiang
 */
/**
 * 所有模块ID
 */
export const Modules = {
    AllOperators: {id: 1, name: '所有操作'},
    OrganizationManage: {id: 2, name: '机构管理'},
    StaffManage: {id: 3, name: '员工管理'},
    UserManage: {id: 5, name: '用户管理'},
    UserTypeManage: {id: 11, name: '用户类型管理'},
    ActivityManage: {id: 6, name: '活动管理'},
    CustomerCenter: {id: 7, name: '客服中心'},
    BusinessData: {id: 8, name: '商业数据'},
    StationManage: {id: 9, name: '服务站管理'},
    CateogryManage: {id: 10, name: '分类管理'},
    PointsManage: {id: 12, name: '积分管理'},
    HospitalManage: {id: 36, name: '医院管理'},
    // 业务管理 相关
    AllBusinessModules: {ids: [13, 14, 15, 16]},
    GoodsManage: {id: 13, name: '商品管理'},
    ServiceManage: {id: 14, name: '服务管理'},
    FoodsManage: {id: 15, name: '餐品管理'},
    MedicalManage: {id: 16, name: '医疗管理'},
    TakeAwayManage: {id: 53, name: '外卖管理'},
    // 订单相关
    AllOrderModules: {ids: [17, 18, 19, 20]},
    GoodsOrder: {id: 17, name: '商品订单'},
    ServiceOrder: {id: 18, name: '服务订单'},
    FoodsOrder: {id: 19, name: '餐品订单'},
    MedicalOrder: {id: 20, name: '医疗订单'},
    TakeAwayOrder: {id: 54, name: '外卖订单'},

    VillageManage: {id: 21, name: '小区管理'},
    // 所有站点业务
    AllStationBusinessModule: {ids: [22, 23, 24, 25]},
    StationGoods: {id: 22, name: '站点商品'},
    StationService: {id: 23, name: '站点服务'},
    StationFoods: {id: 24, name: '站点餐品'},
    StationMedical: {id: 25, name: '站点医疗'},
    StationTakeAway: {id: 52, name: '站点外卖'},

    NoticeManage: {id: 26, name: '通知管理'},
    DataStatistics: {id: 27, name: '数据统计'},
    // 用户体验优化1.0(东方e家) 需求中暂时去掉
    // HospitalEmpower: { id: 28, name: '医院授权' },
    StationSupply: {id: 29, name: '站点供应商'},
    BannerConfig: {id: 31, name: '轮播图配置'},
    HomeSkill: {id: 30, name: 'home技能配置'},
    IotDevice: {id: 32, name: 'Iot设备管理'},
    AllNewsModules: {ids: [33, 35]},
    NewsManage: {id: 33, name: '新闻管理'},
    NewsTypeManage: {id: 35, name: '新闻类型'},
    AllVideoClassModules: {ids: [38, 39]},
    VideoClassManage: {id: 39, name: '社区视频'},
    VideoClassTypeManage: {id: 38, name: '视频类型'},
    Signature: {id: 34, name: '签名管理'},
    /**
     * 已移除，禁止使用
     * @deprecated
     */
    Supervise: {id: 44, name: '监管大屏'},
    /** s
     * 已移除，禁止使用
     * @deprecated
     */
    PhoneBookManage: {id: 37, name: '通讯录管理'},
    DeviceManage: {id: 40, name: '设备管理'},
    LauncherManage: {id: 41, name: '桌面管理'},
    BusinessLauncherManage: {id: 45, name: '行业桌面管理'},
    NewCustomerCenter: {id: 42, name: '客服中心'},
    GridmanManage: {id: 43, name: '社区治理'},
    SignInManage: {id: 44, name: '签到'},
    LiveManage: {id: 50, name: '直播管理'},

    // 社区业态
    SupplierManage: {id: 4, name: '供应商管理'},
    ConsumeStandindBook: {id: 51, name: '消费台账'},
    CloseAccountStandindBook: {id: 51, name: '结算台账'},
    BillingProfile: {id: 51, name: '台账总览'},
    // 分成台账
    DistributionStandindBook: {id: 55, name: '分销台账'},
    // 抽成台账
    CommissionStandindBook: {id: 56, name: '返佣台账'},

    // 我的账户
    AccountManage: {id: 2, name: '我的账户'},
    // 家庭医生
    FamilyDoctorManage: {id: 60, name: '家庭医生'},
    // RSA开放平台
    ModuleRSAOpenPlatformManager: {id: 61, name: 'RSA开放平台'},
};
